// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spoilerTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: darkgrey;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    outline: none; 
    font-weight: bold;
    margin-top: 20px;
    border-radius: 5px;
}

.spoilerTitle .icon {
    right: 0;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Spoiler/Spoiler.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,0BAA0B;IAC1B,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,QAAQ;IACR,oBAAoB;IACpB,kBAAkB;AACtB","sourcesContent":[".spoilerTitle {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n    background-color: darkgrey;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    outline: none; \n    font-weight: bold;\n    margin-top: 20px;\n    border-radius: 5px;\n}\n\n.spoilerTitle .icon {\n    right: 0;\n    margin-right: 0.5rem;\n    margin-top: 0.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
