import { motion } from "framer-motion"
import './button.css';

export const Button = ({ className, src, alt, type, onClick, style={} }) => {
  const combinedclassName = `${className} undraggable`;
  if (type == "submit") {
    return (
      <button className={className} type={type} onClick={onClick}>
      <motion.div
        whileHover={{ scale: 1.03, transition: { duration: 0.07 } }}
        // whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        style={{width:"100%", height:"100%"}}>
          <img style={{width: "100%", height:"100%", objectFit:"contain", ...style}} src={src} alt={alt} className="undraggable"/>
      </motion.div>
      </button>
    );
  } else {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}>
        <img className={combinedclassName} src={src} alt={alt}/>
      </motion.div>
    );
  }
}

export default Button;

export const TextButton = ({ text="Press", onClick, style, width=120, textSize="1.15rem" }) => {
  return (
    <motion.div
        className="motionDiv"
        whileHover={{ scale: 1.03, transition: { duration: 0.07 } }}
        // whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        style={{width, ...style}}>
    <button className="buttonBackground" style={{}} onClick={onClick}>
      <h1 className="buttonText" style={{fontSize: textSize}}>{text}</h1>
    </button>
    </motion.div>
  );
}

export const LigherTextButton = ({ text="Press", onClick, style, width=125, textSize="1.1rem" }) => {
  return (
    <motion.div
        className="motionDiv"
        whileHover={{ scale: 1.03, transition: { duration: 0.07 } }}
        // whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.9 }}
        style={{width, ...style}}>
    <button className="buttonBackgroundAlt" style={{}} onClick={onClick}>
      <h1 className="buttonText" style={{fontSize: textSize}}>{text}</h1>
    </button>
    </motion.div>
  );
}