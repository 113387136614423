import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button, { TextButton } from '../Button.js';
// import Register from "../../assets/images/high quality/register button.webp";
import Gravidy_G from "../../assets/images/high quality/gravidy logo.webp";
import App_Logo from "../../assets/images/high quality/App Logo.webp";
import './navbar.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { CREATOR_INFO_ENDPOINT } from "../../App.js";
import { isMobile } from "react-device-detect";
import { PRE_REGISTER_MODE } from "../../context/APPSTORE_URL.js";

export const NavBar = (props) => {

        const navigate = useNavigate();

        const onClick = () => {
            navigate("/register")
        }

        useEffect(() => {
                AOS.init();
        }, []);

        function returnNav() {
                return (
                        <div 
                        data-aos="fade-right" 
                        component="header"
                        className={`navBar flex justify-between px-6 py-6 ${props.className}`}
                        >
                                <div className="`item flex items-center relative">
                                        <a href="/"><img
                                        loading="lazy"
                                        src={Gravidy_G}
                                        className="navbar-logo" 
                                        alt="Logo"
                                        /></a>
                                </div>
                                <div className="flex items-center relative">
                                        <a href="/explore" className="nav-item"> 
                                                Explore
                                        </a>
                                        <a href={CREATOR_INFO_ENDPOINT} className="nav-item"> 
                                                Creators
                                        </a>        
                                        <div style={{width: 40}}/>
                                        <TextButton text={PRE_REGISTER_MODE ? "Pre-Register" : "Register"} width={150} textSize={isMobile ? "1rem" : "1.25rem"} onClick={onClick}/>
                                </div>
                        </div>
                );
        }

        return returnNav();
}
