import * as React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { NavBar } from "../../Components/navbar/navbar";
import {PageWrapper} from "../../PageWrapper";
import "./communityguidelines.css";

export const CommunityGuidelinesPage = () => {
  return <div>
    <NavBar></NavBar>
    <div className="CommunityGuidelines" data-aos="fade-down">
    <h1> Community Guidelines </h1>

    <p> <font size="+1"> <em>
      Welcome to Gravidy! We are a short-form social media platform that prioritizes the safety and well-being 
      of everyone involved in our platform. In order to maintain a positive and healthy environment, we have established 
      the following community guidelines:
      </em> </font> </p>

    <p className="LastUpdated"> Last Updated: July 2, 2023 </p>
    
    <div className="MinorSafety">
      <h3> Minor Safety</h3>
      <p>
        Gravidy is a platform for users aged 13 and above, with special precautions for users under age 18. We take the safety 
        of minors very seriously and prohibit any content that depicts or promotes harm to minors in any form. We also do not 
        allow any content that includes or depicts children in a sexual manner or being exploited in any way.
      </p>
    </div>
    
    <div className="Risk">
      <h3> Harmful or Risky Behavior </h3>
      <p>
        Gravidy does not allow any content that depicts or promotes dangerous acts or challenges that could cause harm to oneself or others.
      </p>
    </div>
    
    <div className="SelfHarm">
      <h3> Self-Harm </h3>
      <p>
        We do not permit any content that encourages or depicts self-harm, including but not limited to cutting, eating disorders, or suicidal ideation.
      </p>
    </div>
    
    <div className="Nudity">
      <h3> Nudity and Sexual Activities </h3>
      <p>
        Gravidy is not a platform for pornography, and we do not allow any content that includes nudity or sexual activities. This includes but is not limited to, pornography, sexually explicit content, or nudity.
      </p>
    </div>
    
    <div className="Bullying">
      <h3> Bullying and Harassment </h3>
      <p>
        Gravidy does not tolerate any form of bullying or harassment, including but not limited to, cyberbullying, hate speech, or inciting violence. We encourage users to treat each other with respect and kindness.
      </p>
    </div>
    
    <div className="Hateful">
      <h3> Hateful or Extremist Rhetoric </h3>
      <p>
        Gravidy does not allow any content that promotes hate speech or extremism in any form, including but not limited to, racism, sexism, homophobia, transphobia, or religious discrimination.
      </p>
    </div>
    
    <div className="Impersonation">
      <h3> Impersonation and Misinformation </h3>
      <p>
        Gravidy does not permit any form of impersonation or misinformation that may mislead or deceive other users. We encourage users to be honest and transparent in their actions and interactions on the platform.
      </p>
    </div>
    
    <div className="Illegal">
      <h3> Illegal Activities </h3>
      <p>
        We prohibit the depiction or promotion of any illegal activities, including but not limited to, drug use, theft, or violence.
      </p>
    </div>
    
    <div className="Copyright">
      <h3> Copyright and Trademark Infringement </h3>
      <p>
        Gravidy respects intellectual property rights and does not permit any copyright or trademark infringement of any kind.
      </p>
    </div>
    
    <p> <em>
      Content that violates these guidelines in any capacity will not be promoted by our Cosmic Feed, as we seek to provide a space for content exploration and creative discovery that expands creator visibility and delights users. We reserve the right to remove any content that violates our community guidelines, including content that may be harmful, misleading, or offensive. We also reserve the right to ban users who violate our guidelines.
      </em> </p>
    </div>
  </div>;
};
