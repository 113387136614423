import * as React from "react";
import { NavBar } from "../../Components/navbar/navbar.js";
import { Footer } from "../../Components/footer/footer.js";
import Background from "../../assets/images/high quality/LighterHomepageBackground.webp"; //Homepage Background
import Gravidy_G from "../../assets/images/high quality/Big_Gravidy_G.webp";
// import Gravidy_Logo_Video from "../../assets/videos/Gravidy_Logo.webm";
import App_Logo from "../../assets/images/high quality/App Logo.webp";
// import Phone_Shadow from "../../assets/images/high quality/phone shadow.webp";
import Creator_Metrics from "../../assets/images/high quality/creator metrics.webp";
import App_Store from "../../assets/images/high quality/app store button.webp"
import Google_Play_Store from "../../assets/images/high quality/google play button.webp"
import './home.css'
import 'aos/dist/aos.css';
import { CDN_URL } from "../../context/CDN_URL.js";
import { Button } from "@mui/material";
import GravidyLogoGIF from "../../assets/images/GravidyLogoGIF.webp";
import { CREATOR_INFO_ENDPOINT } from "../../App.js";

/**
 * Renders the Home page.
 * @returns {JSX.Element} The rendered Home page component.
 */
export const HomePage = () => {
  const [muted, setMuted] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const handleToggleMute = () => setMuted(current => !current);

  // return ();
  return (
    <div className="home">
      <div className="Background">
        <img src={Background} alt="Gravidy G"/>
      </div>
      <div className="navBar">
          <NavBar className="top_nav" color="white"></NavBar>
      </div>
     <div className="sec1">
      <div className="top-contents">
          <div className="revolution-container-mobile">
            <h4>Revolution&nbsp;</h4>
            <h2>in Social Entertainment</h2>
            <div className="inset-shadow-text"><h3>Scroll Intentionally&nbsp;</h3></div>
          </div>

          <div className="video_player_container" data-aos="fade-right">
            <img id="logo" className="vid_player_behind_g" src={Gravidy_G} alt="Gravidy G"/>
            <video 
              src={CDN_URL + "gravidy_final_script_video.mp4"} 
              style={{ width: "294px", height: "600px", objectFit: "cover", backgroundColor: "#000"}}
              className="video_player" 
              autoPlay 
              loop 
              muted={muted}
              preload="auto"
              // onLoad={() => setLoading(false)}
              // onLoadStart={() => console.log("loading")}
              onPlay={() => setLoading(false)}
              // onPause={() => console.log("paused")}
              // onStalled={() => console.log("stalled")}
              //onLoadedData={() => setLoading(false)}
            >
            </video>
            {loading ?
            <div style={{height: "600px"}}>
            <img id="loading" src={GravidyLogoGIF} style={{height: 100}} alt="Gravidy GIF"/>
            </div> : 
            <div style={{backgroundColor: "rgba(0,0,0,0)"}}>          
            <Button style={{zIndex: 10, backgroundColor: "rgba(10,10,10,.25)", color: "#FFF", position: "absolute", bottom: 10, right: 10}} onClick={handleToggleMute} className="control">{muted ? 'Unmute' : 'Mute'}</Button>
            </div>}
          </div>

          <div className="revolution-container" data-aos="fade-left" >
            <h4>Revolution&nbsp;</h4>
            <h2>in Social Entertainment</h2>
            <div className="inset-shadow-text"><h3>Scroll Intentionally&nbsp;</h3></div>
          </div>
        </div>
    </div>
    <div className="sec2">
      <div className="middle-contents">
        <div className="explore-text" data-aos="fade-right">
          <h1>The Dynamic<br></br>Feed Experience</h1> 
          <a href="/explore">Explore Gravidy {'>'}</a>
          <div className="slider-text">
            <p>Our slider interface transforms 
            entertainment into a moving
            experience: seamlessly share your
            feedback while scrolling</p>
          </div>
        </div>
        <div className="video_player_container_second" data-aos="fade-left">
          {/* <img src={Phone_Shadow} alt="Phone Shadow"/>   */}
          <video 
              src={CDN_URL + "slider_temp_demo.mp4"} 
              style={{ width: "294px", height: "600px", objectFit: "fill", backgroundColor: "#000", filter: 'drop-shadow(0 0 30px rgba(200, 200, 200, 1))'}}
              className="video_player" 
              autoPlay 
              loop 
              muted={true}
              preload="auto"
            >
            </video>
            <div id="second_video_container">
              <div style={{position: "absolute", backgroundColor: "black", top: 0, right: 0, left: 0, height: 24, borderTopLeftRadius: 20, borderTopRightRadius: 20}}/>
              <h2 style={{position: "absolute", bottom: 160, color: "white", opacity: 0.7}}>Platform<br/>Loading...</h2>
            </div>
        </div>
      </div>
    </div>
    <div className="sec3">
      <div className="sec3-text">
        <div className="window-text" data-aos="fade-right">
          <h1>Open the Window
          to Your Audience</h1>
          <a href={CREATOR_INFO_ENDPOINT}>Create on Gravidy {'>'}</a>
        </div>
        <img className="creator-metrics-mobile" src={Creator_Metrics} alt="Creator Metrics Graphic"/>
        <div className="gain-insight-text" data-aos="fade-left">
          <p>Receive the new standard of
          audience feedback and reactions
          with our new creator analytics</p>
        </div>
      </div>
      <img className="creator-metrics-desktop" src={Creator_Metrics} alt="Creator Metrics Graphic" data-aos="fade-up"/>
    </div>
    <div className="sec4">
      <div className="sec4-text" data-aos="fade-right">  
        <h2>A New Venue for<br/>Social Entertainment</h2>   
        <h1>Coming Soon</h1>
        
        <div className="mobile-store-buttons-container">
          <a><img className="app-logo" src={App_Logo} alt="Gravidy App Logo"/></a>
          <a><img src={Google_Play_Store} alt="Google Play Store Button"/></a>
          <a><img src={App_Store} alt="App Store Button"/></a>
        </div>
      </div>
      <div className="sec4-img" data-aos="fade-left">
        <img style={{width: "190px"}} src={GravidyLogoGIF} alt="Gravidy Logo + Spiral"/>
      </div>
    </div>
    <div className="footer">
      <Footer className="bottom_footer" color="white"></Footer>
    </div>
    </div>
    )
};
