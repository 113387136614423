import "./parallax.css";
import { NavBar } from '../../Components/navbar/navbar.js';
import Parallax1 from '../../assets/images/high quality/parallax_1.webp';
import Parallax2 from '../../assets/images/high quality/parallax_2.webp';
import Parallax3 from '../../assets/images/high quality/parallax_3.webp';
import { Footer } from "../../Components/footer/footer.js";
import { CREATOR_SIGNUP_ENDPOINT } from "../../App.js";


export default function App() {

  const ParallaxComponent_1 = () => {
    return (
        <div>
            <h1>So Take<br></br>The Stage</h1>
            <a href={CREATOR_SIGNUP_ENDPOINT}>Create on Gravidy {'>'}</a>
            <img src={Parallax1}></img>
        </div>
    )
  }

  const ParallaxComponent_2 = () => {
    return (
        <div>
            <h1>Define Success<br/> For Yourself</h1>
            <a href={CREATOR_SIGNUP_ENDPOINT}>Create on Gravidy {'>'}</a>
            <img src={Parallax2}></img>
            <h2>Creator Metrics</h2>
            <p>Gravidy’s audience feedback through our <br/>
              Dynamic Slider is contextualized for you to <br/>
              view within never-before-seen metric displays <br/>
              that open the curtains to your audience.
            </p>
        </div>
    )
  } 

  const ParallaxComponent_3 = () => {
    return (
        <div>
          <h1>A High-Tier <br/>Solution</h1>  
          <img src={Parallax3}></img>
          <a href={CREATOR_SIGNUP_ENDPOINT}>Create on Gravidy {'>'}</a>
          <p>
            Follow any creator you like, but 5 is all you get <br/> 
            while assembling your high-tier favorites.
            <br/> <br/>
            Creators: no more uncertainty about whether <br/> 
            your followers will get your content. Unlock <br/> 
            your staying power and build a sustainable <br/>
            community you can trust.
          </p>
        </div>
    )
  }  

  const parallax_list = [ParallaxComponent_3, ParallaxComponent_2, ParallaxComponent_1];
  // ^ this also affected ordering of the stylings in the parallax.css file
  return (
    <div className="parallax" data-aos="fade-up">
      <NavBar className="creator-nav"/>
      {parallax_list.map((Component, id) => (
          <section className={`section-${id}`}> 
            <Component />
          </section>
      ))}
      <div className="footer">
        <Footer className="bottom_footer" color="white"></Footer>
    </div>
    </div>
  );
}
