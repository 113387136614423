// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.svg-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%; /* Makes the div circular */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden; /* Ensures SVGs don't overflow the circular div */
}

.svg-wrapper {
    width: 100%; /* Make sure SVGs take the full circle */
    height: 100%; /* Make sure SVGs take the full circle */
}`, "",{"version":3,"sources":["webpack://./src/pages/creator/Circle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB,EAAE,2BAA2B;IAC/C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB,EAAE,iDAAiD;AACvE;;AAEA;IACI,WAAW,EAAE,wCAAwC;IACrD,YAAY,EAAE,wCAAwC;AAC1D","sourcesContent":[".svg-container {\n    display: flex;\n    justify-content: center;\n    gap: 20px;\n}\n\n.svg-icon {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%; /* Makes the div circular */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n    overflow: hidden; /* Ensures SVGs don't overflow the circular div */\n}\n\n.svg-wrapper {\n    width: 100%; /* Make sure SVGs take the full circle */\n    height: 100%; /* Make sure SVGs take the full circle */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
