import { LOGIN_USER } from "./siteActions";

//FILLER CODE FOR NOW
const siteReducer = (state, action) => {
  if (action.type === "alert type here") {
    const { alertAction, alertMessage, alertType } = action.payload;
    return {
      ...state,
    };
  }
};

export default siteReducer;
