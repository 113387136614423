// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 12px;
  padding-right: 36px;
  overflow: hidden;
  z-index: 1;
}


.footer-link {
  color: white;
  text-align: center;
  font-size: 1.25rem; 
  font-weight: bold;
  letter-spacing: 0.05em; 
  margin: 12px 32px; 
}

.register-img {
  width: 165px;
  aspect-ratio: 2.54;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
}

.footer-button {
  width: 200px;
}

@media (max-width: 980px) {
  .footer-link { 
    font-size: 1rem;
    margin: 0 16px;
  }
}

@media (max-width: 900px) {
  .footer-link { 
    font-size: 0.9rem;
    margin: 0 16px;
  }

  .footer-button {
    width: 150px;
  }
}

@media (max-width: 480px) {
  .footer-link {
    font-size: 0.5rem;
    margin: 0 13px;
  }
  
  .footer-button {
    width: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/footer/footer.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;;AAGA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,eAAe;IACf,cAAc;EAChB;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".footer-container {\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  flex-direction: row;\n  padding-bottom: 12px;\n  padding-right: 36px;\n  overflow: hidden;\n  z-index: 1;\n}\n\n\n.footer-link {\n  color: white;\n  text-align: center;\n  font-size: 1.25rem; \n  font-weight: bold;\n  letter-spacing: 0.05em; \n  margin: 12px 32px; \n}\n\n.register-img {\n  width: 165px;\n  aspect-ratio: 2.54;\n  object-fit: contain;\n  object-position: center;\n  overflow: hidden;\n  flex-shrink: 0;\n  max-width: 100%;\n}\n\n.footer-button {\n  width: 200px;\n}\n\n@media (max-width: 980px) {\n  .footer-link { \n    font-size: 1rem;\n    margin: 0 16px;\n  }\n}\n\n@media (max-width: 900px) {\n  .footer-link { \n    font-size: 0.9rem;\n    margin: 0 16px;\n  }\n\n  .footer-button {\n    width: 150px;\n  }\n}\n\n@media (max-width: 480px) {\n  .footer-link {\n    font-size: 0.5rem;\n    margin: 0 13px;\n  }\n  \n  .footer-button {\n    width: 100px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
