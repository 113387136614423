// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommunityGuidelines {
    margin: 20px;
    background: lightgrey;
    padding: 20px;
    border-radius: 10px;
}

.CommunityGuidelines h1 {
    font-size: 30px;
    font-weight: bold;
}

h3 {
    font-weight: 600;
}

p {
    margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/communityguidelines/communityguidelines.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".CommunityGuidelines {\n    margin: 20px;\n    background: lightgrey;\n    padding: 20px;\n    border-radius: 10px;\n}\n\n.CommunityGuidelines h1 {\n    font-size: 30px;\n    font-weight: bold;\n}\n\nh3 {\n    font-weight: 600;\n}\n\np {\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
