import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretLeft } from '@fortawesome/free-solid-svg-icons'

import "./Spoiler.css";

export const Spoiler = ({ title, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div>
          <span className="spoilerTitle" onClick={toggleVisibility}>
            {title}
            {isVisible && <FontAwesomeIcon className="icon" icon={faCaretDown} />}
            {!isVisible && <FontAwesomeIcon className="icon" icon={faCaretLeft} />}
          </span>
          <div >
            {isVisible && <div className={isVisible ? "spoilerContent visible" : "spoilerContent"} >{children}</div>}
          </div>
        </div>
    );
}