// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonBackground {
    background: linear-gradient(to right bottom, #382DE4 0%, #CA46AA 100%);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.buttonBackgroundAlt {
    background: linear-gradient(to right bottom, #5b3effd7 0%, #ffabebd7 100%);
    opacity: 1;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    transform: translateY(-20%);
    align-items: center;
    width: 100%;
    height: 100%;
}
.buttonText {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 6%;
    margin-bottom: 6%;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.motionDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/button.css"],"names":[],"mappings":"AAAA;IACI,sEAAsE;IACtE,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;AACA;IACI,0EAA0E;IAC1E,UAAU;IACV,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,2BAA2B;IAC3B,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;AACA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,4CAA4C;;AAEhD;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".buttonBackground {\n    background: linear-gradient(to right bottom, #382DE4 0%, #CA46AA 100%);\n    border-radius: 7px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n}\n.buttonBackgroundAlt {\n    background: linear-gradient(to right bottom, #5b3effd7 0%, #ffabebd7 100%);\n    opacity: 1;\n    border-radius: 15px;\n    display: flex;\n    justify-content: center;\n    transform: translateY(-20%);\n    align-items: center;\n    width: 100%;\n    height: 100%;\n}\n.buttonText {\n    color: white;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    font-size: 1.2rem;\n    font-family: Nunito;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin-top: 6%;\n    margin-bottom: 6%;\n    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n\n}\n.motionDiv {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
