// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
}

.user-profile img {
    width: 130px;
    border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserProfile/UserProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".user-profile {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: fit-content;\n}\n\n.user-profile img {\n    width: 130px;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
