// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canvas {
    width: 100vw;
    height: 100vh;
}

.canvas-container {
    position: relative;
    width: 100vw;
}

.canvas [id="goback"] {
    position: absolute;
    color: #FFF;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4vw;
    font-family: 'Nunito', sans-serif;
    /* font-style: italic; */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #FF9EE8, #927ffd);
}

.canvas [id="welcome"] {
    position: absolute;
    color: #FFF;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4vw;
    font-family: 'Nunito', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/explore/explore.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gCAAgC;IAChC,cAAc;IACd,iCAAiC;IACjC,wBAAwB;IACxB,6BAA6B;IAC7B,oCAAoC;IACpC,6DAA6D;AACjE;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,gCAAgC;IAChC,cAAc;IACd,iCAAiC;AACrC","sourcesContent":[".canvas {\n    width: 100vw;\n    height: 100vh;\n}\n\n.canvas-container {\n    position: relative;\n    width: 100vw;\n}\n\n.canvas [id=\"goback\"] {\n    position: absolute;\n    color: #FFF;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 4vw;\n    font-family: 'Nunito', sans-serif;\n    /* font-style: italic; */\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-image: linear-gradient(to right, #FF9EE8, #927ffd);\n}\n\n.canvas [id=\"welcome\"] {\n    position: absolute;\n    color: #FFF;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-size: 4vw;\n    font-family: 'Nunito', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
