import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Circle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube} from '@fortawesome/free-brands-svg-icons'

const Circle = ({selectedId, selectCircle}) => {

    const circleTexts = [faYoutube, faTiktok, faInstagram];

    const circleContainer = {
        margin: "30px 0 20px 0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "30px",
    }

    const circle = {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        backgroundColor: "#D9D9D9",
        display: "flex",
        color: 'black',
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8rem",
        cursor: "pointer",
    }

    return (

        <div style={circleContainer}>
            {circleTexts.map((text, index) => (
                <motion.div
                    key={index}
                    style={circle}
                    className={`${selectedId === index ? 'selected' : ''}`}
                    onClick={() => selectCircle(index)}
                    animate={{ scale: selectedId === index ? 1.2 : 1 }}
                    whileTap={{ scale: selectedId === index ? 1.2 : 1.1 }}
                >
                    <FontAwesomeIcon icon={text} size="2x" />
                </motion.div>
            ))}
        </div>
    );
};

export default Circle;
