import * as React from "react";
import { useNavigate } from "react-router-dom";
import Register from "../../assets/images/high quality/register for gravidy.webp";
import Button from '../Button.js';
import './footer.css';



export const Footer = (props) => {

    const navigate = useNavigate();

    const onClick = () => {
        navigate("/register")
    }

    return (

        <footer className="footer-container">
            <a href="https://www.gravityplatforms.com" className="footer-link"> 
                Gravity Platforms Inc.
            </a>
            <a href="/communityguidelines" className="footer-link"> 
                Community Guidelines
            </a>
            <a href="/tos" className="footer-link"> 
                Terms of Service
            </a>
            <div className="footer-register-img-container">
                <Button
                    className="footer-button"
                    type="submit"
                    src={Register}
                    alt="Register Button"
                    onClick={onClick}
                />
            </div>
        </footer>
    );
}
