import * as React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { NavBar } from "../Components/navbar/navbar";
import {PageWrapper} from "../PageWrapper";


export const PrivacyPolicyPage = () => {
  return  <div style={{marginLeft: "30px", marginRight: "30px", backgroundColor: "white"}}>

        <h1>Privacy Policy</h1>
        
        <p>Last updated: August 20, 2023</p>
        
        <br/>
        <p dir="ltr">
            This Privacy Policy describes our policies and procedures on the collection,
            use and disclosure of your information when you use the Platform and tells
            you about your privacy rights and how the law protects you.  THE PRIVACY
            POLICY BELOW GOVERNS YOUR USE OF GRAVIDY AND ALL OTHER GRAVITY PLATFORMS
            ONLINE SERVICES. We use your personal data to provide and improve the
            Platform. By using the Platform, you agree to the collection and use of
            information in accordance with this Privacy Policy.
        </p>

        <p dir="ltr">
            The Privacy Policy,{" "}
            <a href="https://www.gravidy.app/tos">Terms of Service</a>, 
            and{" "}<a href="https://www.gravidy.app/communityguidelines">Community Guidelines</a>
            are mutually incorporated. Please read these in
            their entirety. Your continued use of Gravity Platforms services implies
            your consent and acceptance of all agreements. Gravidy is offered to you
            conditioned on your acceptance without modification of the terms,
            conditions, and notices contained herein (the "Terms"). Your use of Gravity
            Platforms constitutes your agreement to all such Terms. Please read these
            terms carefully; you can refer back to this site in the future.
        </p>


        <h2> Interpretation and Definitions </h2>


        <h3> Interpretation </h3>
        <p dir="ltr">
            The words of which the initial letter is capitalized have meanings defined
            under the following conditions. The following definitions shall have the
            same meaning regardless of whether they appear in singular or in plural.
        </p>

        <h3> Definitions </h3>
        <p dir="ltr">
            For the purposes of these Terms of Service:
        </p>
        <p dir="ltr">
            <strong>Account</strong> means a unique account created for you to access our Service or
            parts of our Service.
        </p>

        <p dir="ltr">
            <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
            control with a party, where "control" means ownership of 50% or more of the
            shares, equity interest or other securities entitled to vote for election of
            directors or other managing authority.
        </p>

        <p dir="ltr">
            <strong>Application</strong> or <strong>Platform</strong> refers to any software program provided by the
            Company (the Gravidy mobile application and the gravidy.app website
            application).
        </p>

        <p dir="ltr">
            <strong>Company</strong> (referred to as either "Gravity Platforms", "we", "us" or "our" in
            this Agreement) refers to Gravity Platforms.
        </p>

        <p dir="ltr">
            <strong>Content</strong> is users' published posts on the Platform, including comments
        </p>
        <p dir="ltr">
            <strong>Device</strong> means any device that can access the Service such as a computer, a
            cellphone or a digital tablet.
        </p>

        <p dir="ltr">
            <strong>Personal Data</strong> is any information that relates to an identified or
            identifiable individual.
        </p>

        <p dir="ltr">
            <strong>Service Provider</strong> means any natural or legal person who processes the data on
            behalf of the Company. It refers to third-party companies or individuals
            employed by the Company to facilitate the Service, to provide the Service on
            behalf of the Company, to perform services related to the Service or to
            assist the Company in analyzing how the Service is used.
        </p>

        <p dir="ltr">
            <strong>Stakeholder</strong> refers to the Company mission's three main stakeholders: users,
            creators, and advertisers.
        </p>

        <p dir="ltr">
            <strong>Third-party Service</strong> refers to any website or any social network website
            through which a user can log in or create an account to use the Service.
        </p>

        <p dir="ltr">
            <strong>The General Data Protection Regulation ("GDPR")</strong> is a European Regulation
            concerning the use and processing of personal information.
        </p>

        <p dir="ltr">
            <strong>Usage Data</strong> refers to data collected automatically, either generated by the
            use of the Service or from the Service infrastructure itself (for example,
            the duration of a page visit).
        </p>

        <br/>

        <h3> Collecting and Using Your Data</h3>

        <p dir="ltr">
            Gravity Platforms has a legitimate interest in collecting and using your
            information in a strictly secure and reasonable way. We are committed to
            improving the entertainment experience of our Stakeholders by incorporating
            higher-quality feedback that is collected from Our Platform. Pursuing our
            goals is not in conflict with your rights, and our use of your information
            is to improve our services and provide you with the experience you desire.
            This provides us with a lawful purpose to use your information. We will use
            reasonable efforts to provide you:
        </p>
        <ul>
            <li dir="ltr">
                <p dir="ltr">
                    Access to your personal information
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Correction of the personal information that we hold
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Restrictions on processing your personal information
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Transfer of your personal information to another party
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Deletion of your personal information
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    The ability to communicate with us about your particular situation
                </p>
            </li>
        </ul>
        <br/>
        <p dir="ltr">
            Personal Data
        </p>
        <p dir="ltr">
            While using our Application, in order to provide features of our
            Application, we may collect, with your prior permission:
        </p>
        <ul>
            <li dir="ltr">
                <p dir="ltr">
                    Email address
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Birthday
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    First name and last name
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Phone number
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Information regarding your location
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Information from your Device's phone book (contacts list)
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    Pictures and other information from your Device's camera and photo
                    library
                </p>
            </li>
        </ul>
        <br/>
        <p dir="ltr">
            We use this information to provide features of our Platform, to improve and
            customize our Platform. The information may be uploaded to Gravity's servers
            and/or a Platform Provider's server or it may be simply stored on your
            device. You can enable or disable access to this information at any time,
            through your Device settings. The security of your Personal Data is
            important to us, but remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure. While we strive to
            use commercially acceptable means to protect your Personal Data, we cannot
            guarantee its absolute security.
        </p>
        <br/>
        <p dir="ltr">
            Usage Data
        </p>
        <p dir="ltr">
            Usage Data is collected automatically when using the Platform. Usage Data
            may include information such as your Device's Internet Protocol address
            (e.g. IP address), browser type, browser version, the pages of our Platform
            that you visit, the time and date of your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data. When you access
            the Platform by or through a mobile device, We may collect certain
            information automatically, including, but not limited to, the type of mobile
            device you use, your mobile device unique ID, the IP address of your mobile
            device, your mobile operating system, the type of mobile Internet browser
            you use, unique device identifiers and other diagnostic data. We may also
            collect information that your browser sends whenever you visit our Platform
            or when you access the Platform by or through a mobile device.
        </p>
        <br/>
        <p dir="ltr">
            No Unlawful or Prohibited Use/Intellectual Property
        </p>
        <p dir="ltr">
            By posting, uploading, inputting, providing or submitting your Media you are
            granting Gravity Platforms, our affiliated companies and necessary
            sublicensees permission to use your Media in connection with the operation
            of their Internet businesses including, without limitation, the rights to:
            copy, distribute, transmit, publicly display, publicly perform, reproduce,
            edit, translate and reformat your Media; and to publish your name in
            connection with your Content.
        </p>
        <br/>
        <p dir="ltr">
            No compensation will be paid with respect to the use of your Media, as
            provided herein. Gravity Platforms is under no obligation to post or use any
            Content you may provide and may remove any Content at any time in Gravity
            Platforms's sole discretion. By posting, uploading, inputting, providing or
            submitting your Media you warrant and represent that you own or otherwise
            control all of the rights to your Media as described in this section
            including, without limitation, all the rights necessary for you to provide,
            post, upload, input or submit the Media.
        </p>
        <br/>
        <p dir="ltr">
            Consenting to Third-Party Data Usage
        </p>
        <p dir="ltr">
            With your consent, we may share your personal information with other users.
            When you share personal information or otherwise interact in the public
            areas with other users, such information may be viewed by all users and may
            be publicly distributed outside.
        </p>
        <br/>
        <p dir="ltr">
            If you interact with other users or register through a Third-Party Platform,
            your contacts on the Third-Party Platform may see your name, profile,
            pictures and description of your activity. Similarly, other users will be
            able to view descriptions of your activity, communicate with you and view
            your profile. We have no control over and assume no responsibility for the
            privacy policies or practices of a Third-Party Platform that you provide
            your information to.  You may also have the option of sharing additional
            information with Gravity through your Third-Party Platform's account. If you
            choose to provide such information and Personal Data, during registration or
            otherwise, you are giving Gravity permission to use, share, and store it in
            a manner consistent with this Privacy Policy.
        </p>
        <br/>
        <p dir="ltr">
            Our Platform may contain links to other applications or services that are
            not operated by us. If you click on a Third-Party link, you will be directed
            to that Third-Party's service. We strongly advise you to review the Privacy
            Policy of every service you visit. We have no control over and assume no
            responsibility for the content, privacy policies or practices of any
            Third-Party applications or services.
        </p>
        <br/>
        <p dir="ltr">
            Retention of Your Data
        </p>
        <p dir="ltr">
            Gravity will retain your Personal Data only for as long as is necessary for
            the purposes set out in this Privacy Policy. We will retain and use your
            Personal Data to the extent necessary to comply with our legal obligations
            (for example, if we are required to retain your data to comply with
            applicable laws), resolve disputes, and enforce our legal agreements and
            policies. Gravity will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of time,
            except when this data is used to strengthen the security or to improve the
            functionality of Our Platform, or we are legally obligated to retain this
            data for longer time periods.
        </p>
        <br/>
        <p dir="ltr">
            Transfer of Your Data
        </p>
        <p dir="ltr">
            Your information, including Personal Data, is processed at Gravity's
            operating offices and in any other places where the parties involved in the
            processing are located. It means that this information may be transferred to
            - and maintained on - computers located outside of your state, province,
            country or other governmental jurisdiction where the data protection laws
            may differ from those from your jurisdiction.
        </p>
        <p dir="ltr">
            Your consent to this Privacy Policy followed by your Content of such
            information represents your agreement to that transfer.
        </p>
        <br/>
        <p dir="ltr">
            Gravity will take all steps reasonably necessary to ensure that your data is
            treated securely and in accordance with this Privacy Policy. No transfer of
            your Personal Data will take place to an organization or a country unless
            there are adequate controls in place including the security of your data and
            other personal information.
        </p>
        <br/>
        <p dir="ltr">
            Deletion of Your Data
        </p>
        <p dir="ltr">
            You have the right to delete or request that we assist in deleting the
            Personal Data that we have collected about you. Our Platform may offer the
            ability to delete certain information about you from within the Platform.
            You may update, amend, or delete your information at any time by signing in
            to your Account, if you have one, and visiting the account settings section
            that allows you to manage your personal information. You may also contact us
            to request access to, correct, or delete any personal information that you
            have provided to us.
        </p>
        <br/>
        <p dir="ltr">
            Please note, however, that we may need to retain certain information when we
            have a legal obligation or lawful basis to do so.
        </p>
        <br/>
        <p dir="ltr">
            Disclosure of Your Data
        </p>
        <p dir="ltr">
            Under certain circumstances, Gravity may be required to disclose your
            Personal Data if required to do so by law or in response to valid requests
            by public authorities (e.g. a court or a government agency). Gravity may
            disclose your Personal Data in the good faith belief that such action is
            necessary to:
        </p>
        <ul>
            <li dir="ltr">
                Comply with a legal obligation
            </li>
            <li dir="ltr">
                Protect and defend the rights or property of Gravity
            </li>
            <li dir="ltr">
                Prevent or investigate possible wrongdoing in connection with the
                Platform
            </li>
            <li dir="ltr">
                Protect the personal safety of Users of the Platform or the public
            </li>
            <li dir="ltr">
                Protect against legal liability
            </li>
        </ul>
        <br/>
        <p dir="ltr">
            Safety
        </p>
        <br/>
        <p dir="ltr">
            Children and Minors
        </p>
        <p dir="ltr">
            Our Platform is not offered to anyone under the age of 16, and cautions
            restrictions around the accounts of users under 18. We do not knowingly
            collect personally identifiable information from anyone under the age of 16.
            If you are a parent or guardian and you are aware that your child has
            provided us with Personal Data, please contact us at{" "}
            <a href="mailto:legal@gravityplatforms.com">legal@gravityplatforms.com
            </a>
            . If we become aware that we have collected Personal Data from anyone under
            the age of 16 without verification of parental consent, we will take steps
            to remove that information from our servers. If we need to rely on consent
            as a legal basis for processing your information and your country requires
            consent from a parent, we may require your parent's consent before we
            collect and use that information.
        </p>
        <h4 dir="ltr">
            Personal Information Under the General Data Protection Regulation
        </h4>
        <p dir="ltr">
            Gravity Platforms is based in the United States and is subject to the laws
            of the United States. Use of our Platforms and/or Content of personal and/or
            personally identifiable information means that you consent to this Privacy
            Policy and to the storage and transfer of that information to/from the
            United States. While located in the United States, Gravity Platforms is
            committed to processing your information in compliance with the GDPR, or
            General Data Protection Regulation. Gravity Platforms will make commercially
            reasonable efforts to securely process and store personal information as
            specified under the GDPR, including using reasonable efforts to ensure that
            any third-party services we utilize adhere to the General Data Protection
            Regulation.
        </p>
        <br/>
        <p dir="ltr">
            Why We Collect Information
        </p>
        <p dir="ltr">
            In sum, Gravity may use Personal Data and Usage Data for the following
            purposes:
        </p>
        <ul>
            <li dir="ltr">
                <p dir="ltr">
                    To provide and maintain our Platform, including to monitor the usage
                    of our Platform.
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    To manage your Account: to manage your registration as a user of the
                    Platform. The Data you provide can give you access to different
                    functionalities of the Platform that are available to you as a
                    registered user.
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    For the performance of a contract: the development, compliance and
                    undertaking of the purchase contract for the products, items or
                    services you have purchased or of any other contract with us through
                    the Platform.
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    To contact you by email, telephone calls, SMS, or other equivalent
                    forms of electronic communication, such as a mobile application's
                    push notifications regarding updates or informative communications
                    related to the functionalities, products or contracted services,
                    including the security updates, when necessary or reasonable for
                    their implementation.
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    To provide you with news, special offers and general information
                    about other goods, services and events which we offer that are
                    similar to those that you have already purchased or enquired about
                    unless you have opted not to receive such information.
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    To manage your requests: To attend and manage your requests to us.
                </p>
            </li>
            <li dir="ltr">
                <p dir="ltr">
                    For business transfers: we may use your information to evaluate or
                    conduct a merger, divestiture, restructuring, reorganization,
                    dissolution, or other sale or transfer of some or all of our assets,
                    whether as a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which Data held by us about our Platform
                    users is among the assets transferred.
                </p>
            </li>
            <ul>
                <li dir="ltr">
                    <p dir="ltr">
                        For example, if Gravity is involved in a merger, acquisition or
                        asset sale, your Data may be transferred. We will provide notice
                        before your Data is transferred and becomes subject to a
                        different Privacy Policy.
                    </p>
                </li>
            </ul>
            <li dir="ltr">
                <p dir="ltr">
                    For other purposes: we may use your information for other purposes,
                    such as data analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns and to evaluate and
                    improve our Platform, products, services, marketing and your
                    experience.
                </p>
            </li>
        </ul>
        <br/>
        <p dir="ltr">
            Communication and Updates
        </p>
        <br/>
        <p dir="ltr">
            Changes to this Privacy Policy
        </p>
        <p dir="ltr">
            We may update our Privacy Policy from time to time. We will notify you of
            any changes by posting the new Privacy Policy on this page.
        </p>
        <p dir="ltr">
            We will provide notice on our Platform prior to making changes. Changes to
            this Privacy Policy are effective when they are posted on this page as per
            the "Last updated" date at the top of this Privacy Policy.
        </p>
        <br/>
        <p dir="ltr">
            Contact Us
        </p>
        <p dir="ltr">
            If you have any questions about this Privacy Policy, you can contact us at{" "}
            <a href="mailto:legal@gravityplatforms.com">legal@gravityplatforms.com</a>.
        </p>
        <br/>



    </div>

   
};
