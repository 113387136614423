// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tospage {
    height: 100vh
}

.tos {
    margin: 20px;
    padding: 20px;
    background: lightgrey;
    border-radius: 10px;
}

h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

h2 {
    font-size: 25px;
    font-weight: 700;
}

p a {
    color: #554AFF;
}

.spoiler {
    margin-bottom: 20px;
}

.spoilerContent {
    background-color: #f8f9fa;
    padding: 10px;
    margin-top: 0px;
    border: 1px solid #ccc; 
    border-radius: 5px;
    overflow: hidden;
}


`, "",{"version":3,"sources":["webpack://./src/pages/tos/tos.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".tospage {\n    height: 100vh\n}\n\n.tos {\n    margin: 20px;\n    padding: 20px;\n    background: lightgrey;\n    border-radius: 10px;\n}\n\nh1 {\n    font-size: 30px;\n    font-weight: bold;\n    margin-bottom: 10px;\n}\n\nh2 {\n    font-size: 25px;\n    font-weight: 700;\n}\n\np a {\n    color: #554AFF;\n}\n\n.spoiler {\n    margin-bottom: 20px;\n}\n\n.spoilerContent {\n    background-color: #f8f9fa;\n    padding: 10px;\n    margin-top: 0px;\n    border: 1px solid #ccc; \n    border-radius: 5px;\n    overflow: hidden;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
