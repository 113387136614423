import { useSiteContext } from "../../context/siteContext"
import { CDN_URL } from "../../context/CDN_URL";
import "./UserProfile.css";
import React, { useEffect } from "react";

export const UserProfile = ({userID, message=""}) => {
    const [firstLine, setFirstLine] = React.useState("--");
    const [secondLine, setSecondLine] = React.useState("--");

    const { getUsername } = useSiteContext();

    const retrieveInfoAndSetText = async () => {
        const { name, username } = await getUsername({userID});
        const formatUsername = `@${username}`;

        // configure the text to display (don't show name if it doesn't exist, otherwise show both)
        if (name == null || name == "") {
            setFirstLine(formatUsername);
            setSecondLine("");
        } else {
            setFirstLine(name);
            setSecondLine(formatUsername);
        }
    }

    useEffect(() => {
        // query the name info on first render
        if (userID != null)
        retrieveInfoAndSetText(); // after retrieved, will update state triggering rerender
    }, [userID])

    return (
        <div className="user-profile">
            <img src = {`${CDN_URL}profiles/${userID}.jpg`}></img>
            <h1>{firstLine}</h1>
            <h2>{secondLine}</h2>
            <h1>{message}</h1>
        </div>
    )
}