// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black; /* or any background color */
  z-index: 10000; /* Ensure it's above the homepage */
}

.loading-container video {
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/Components/LoadingScreen.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB,EAAE,4BAA4B;EAC/C,cAAc,EAAE,mCAAmC;AACrD;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".loading-container {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: black; /* or any background color */\n  z-index: 10000; /* Ensure it's above the homepage */\n}\n\n.loading-container video {\n  width: 100%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
