import { NavBar } from "./Components/navbar/navbar";
/**
 * Wrap at the top level of all pages to include the NavBar
 */
export const PageWrapper = ({children}) => {

    return (<div >
        <NavBar color="black"/>
            <div >
                {children}
            </div>
        </div>);
}