import React from 'react'
import './CanvasChild.css'
import { TextButton } from '../Button';

export const CanvasChild = ({ windowHeight, windowWidth, state, x, y, width, height, src, glow, name, onClick, zIndex, onNextClick}) => {

    const glowStyle = glow
        ? { filter: 'drop-shadow(0 0 30px rgba(200, 200, 200, 1))' }
        : {};
    
    const cursor = name === "line1" || name === "line2" || name === "line3" || name === "sphere" || name === "rectangle"
        ? {}
        : {cursor: "pointer"};

    let opacity = {};
    let hover = "";
    let textFunc = text1

    if (state === "graph") {
        if (name === "phone" || name === "heart" || name === "user") {
            opacity = {opacity: 0.5}
            hover = "hover"
        }
        textFunc = text4
    }
    if (state === "user") {
        if (name === "phone" || name === "heart" || name === "graph1" || name === "graph2") {
            opacity = {opacity: 0.5}
            hover = "hover"
        }
        textFunc = text3
    }
    if (state === "phone") {
        if (name === "graph1" || name === "graph2" || name === "user") {
            opacity = {opacity: 0.5}
            hover = "hover"
        }
        textFunc = text2
    }

    let textBoxClassName;
    if (windowWidth > windowHeight) {
        textBoxClassName = "text-desktop";
    } else {
        textBoxClassName = "text-mobile";
    }

    function text1() {
        return (
            <div className={textBoxClassName}>
                <h5>Tuned Against Doom(scrolling)</h5>
                <h4 style={{textAlign: "center"}}>Current platforms use attention-driven algorithms that sell rather than serve you. <br/><br/>They restrict creative freedom and contribute to burning out.</h4>
                <h4 style={{textAlign: "center"}}>They could do better, but they won't.<br/><i>We will.</i></h4>
                <h4 style={{textAlign: "center"}}><h7>Gravidy</h7> is the venue that puts the focus back on you: <h7>engaged audiences</h7> and <h7>passionate creators</h7>.</h4>
                <h4 style={{textAlign: "center"}}><i>Explore the visual to see how</i>.</h4>
            </div>
        )
    }
    //Current platforms use attention-driven algorithms that sell rather than serve you. They restrict creative freedom and contribute to burning out.
    function text2() {
        return (
            <div className={textBoxClassName}>
                <h5>The Dynamic Feed &amp; Slider</h5>
                <h4 style={{textAlign: "center"}}><i>Just one swipe:</i> activate our <h7>Dynamic Slider</h7> to leave a rating and summon the next video.</h4>
                <h3><h7>The algorithm</h7> instantly personalizes your experience based on your engagement, not your attention. </h3>
                <h3><h7>Your engagement</h7> is contextualized for content creators to act upon. Access never-before-seen metric displays that open the curtains to the audience.</h3>
                <h4 style={{textAlign: "center"}}>Take back your agency. You should be in control of what you want to see.</h4>
            </div>
        )
    }

    function text3() {
        return (
            <div className={textBoxClassName}> 
                <h5>A High-Tier Solution</h5>
                <h4 style={{textAlign: "center"}}>Follow any creator you like, but 5 is all you get while assembling your <h7>high-tier favorites</h7>.</h4>
                <h2 style={{textAlign: "center"}}>You can meaningfully support and not miss content from your favorite creators.</h2>
                <h2 style={{textAlign: "center"}}><h7>Creators: </h7>no more questioning whether your fans will even receive your content.</h2>
                <h4 style={{textAlign: "center"}}>Unlock your <h7>staying power</h7> and build a sustainable community you can <h7>trust</h7>.</h4>
                {/* <h4>These tight-knit communities will foster collaboration between creators and audiences while adding another level of user control back to the algorithm.</h4> */}
            </div>
        )
    }

    function text4() {
        return (
            <div className={textBoxClassName}>
                <h5>Gravidy's Algorithm</h5>
                <h2 style={{textAlign: "center"}}>Gravidy's algorithm <h7>hears you</h7> when you use our <h7>Dynamic Slider</h7>, leading to personalization and discovery – all while keeping your privacy secure.</h2>
                <h3 style={{textAlign: "center"}}>Built on our own technologies and AI, it reinforces existing audience-creator relationships while also promoting the discovery of new ones.</h3>
                <h2 style={{textAlign: "center"}}>With <h7>richer engagement</h7> from our Dynamic Slider, there is tremendous potential for the algorithm to fight doom-scrolling and <h7>inspire creativity</h7>, especially as the venue grows.</h2>
            </div>
        )
    }//It is time for a venue that is perfect for creators, audiences, and sponsors alike.

    return (
        <div className="canvas-child" onClick={() => {onClick(name)}} style={{position: "absolute", top: y * windowWidth, left: x * windowWidth, width: width * windowWidth, height: height * windowWidth}}>
            {name !== "text" && name !== "nextbutton" && <img alt={name} className={hover} src={src} style={{...cursor, zIndex, width: width * windowWidth, height: height * windowWidth, ...glowStyle, ...opacity}}/>}
            {name === "text" && name !== "nextbutton" &&
            <div className="textBox">
                {textFunc()}
            </div>}
            {name === "nextbutton" &&
                
                <div className="next">
                    {/* <div onClick = {() => {onNextClick()}} className={textBoxClassName}>
                        <h6>Next {'>'}</h6>
                    </div> */}
                    <TextButton style={{opacity: 0.9}} text=">" width={25} textSize="1rem" onClick={onNextClick}/>
                </div>
            }
        </div>
    )
}