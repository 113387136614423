import * as React from "react";
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import { useSiteContext } from "../../context/siteContext.js";
import Checkmark from "../../assets/images/checkmark.webp";
import Xmark from "../../assets/images/xmark.webp";
import { PageWrapper } from "../../PageWrapper.js";
import TextField from '@mui/material/TextField';
import Gravidy_G from "../../assets/images/high quality/gravidy logo.webp";
import GravidyLogoGIF from "../../assets/images/GravidyLogoGIF.webp";
import { FormControl, Box } from '@mui/material';
import {isMobile} from 'react-device-detect';
import './register.css';
import Button, { TextButton } from '../../Components/Button.js';
import { NavBar } from "../../Components/navbar/navbar.js";
import { APPSTORE_URL, PRE_REGISTER_MODE } from "../../context/APPSTORE_URL.js";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  AsYouType,
  parsePhoneNumber,
} from "libphonenumber-js";

/**
 * The RegisterPage component is responsible for handling user registration.
 * It includes form inputs for email, username, birthday, phone number, password, and confirm password.
 * The component also handles email verification and checks for username availability.
 * Upon successful registration, it displays a success message and provides a link to download the app.
 */
export const RegisterPage = () => {
  const { email2fa, email2faCheck, text2fa, text2faCheck, usernameCheck, register, getUserIDFromUsername } = useSiteContext();
  const navigate = useNavigate();

  // getting the creator invite "i" from the URL
  const { username } = useParams();
  const [htUserID, setHtUserID] = React.useState(null);


  const [params, setParams] = React.useState({
    username: "",
    phone: "+1 " // removed in register function before sending if not valid
  });

  const loadInHTUserID = async () => {
    const parsed = username.split("@")[1];
    const userID = await getUserIDFromUsername({username: parsed});
    if (userID != null) {
    setHtUserID(userID);
    setParams({...params, highTierUID: userID}) // adding this param will trigger ht follow
    }
  }

  React.useEffect(() => {
    if (username)
    loadInHTUserID();
  }, [username])

  const [isValidPassword, setValidPassword] = React.useState(false);
  const [isValidUsername, setUsernameStatus] = React.useState('');
  const [validPhone, setValidPhone] = React.useState(false);
  const conditionalSendText2fa = (phone) => {
    if (isValidPhoneNumber(removeParentheses(phone))) {
      text2fa({ phone });
    }
  }
  const [isErrorVisible, setIsErrorVisible ] = React.useState(false);
    
  const createSetter = (key, additionalBehavior = null, transformValue=(text)=>text) => ({ nativeEvent }) => {
    setParams(prev => ({ ...prev, [key]: transformValue(nativeEvent.target.value) }));
    if (additionalBehavior) additionalBehavior(nativeEvent.target.value);
  };
  
  const setEmail = createSetter('email');
  const setEmailCode = createSetter('emailCode', check2faEmail);
  const setTextCode = createSetter('textCode', check2faText);
  const setUsername = createSetter('username', checkUsername);
  const setBirthday = createSetter('birthday');
  const setPhone = createSetter('phone', conditionalSendText2fa, transformValue);
  const setPassword = createSetter('password', validateStrongPassword);
  const setConfirmPassword = createSetter('confirmPassword');


  const onEnterEmail = () => {
    const { email } = params;
    if (!validateEmail(email)) {
      setValidEmail({
        emailValid: true,
      });
      setIsErrorVisible(true);
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 3000);

      return ;
    }
    email2fa({ email });
    EmailSet2({
      EmailEntered: true,
    });
    return ;
  };

  const onEnterEmailCode = async (code=null) => {
    let { email, emailCode } = params;
    //manually override emailcode
    if (code) {
      emailCode = code;
    }
    const valid = await email2faCheck({ email, emailCode });
    if (!valid) {
      setvalidCode({
        codeinValid: true,
      });
      return <h1>.</h1>;
    }
    EmailSet2({
      EmailValidated: true,
    });
    return <h1>Code worked.</h1>;
  };

  const onEnterTextCode = async (code=null) => {
    let { phone, textCode } = params;
    //manually override textcode
    if (code) {
      textCode = code;
    }
    const valid = await text2faCheck({ phone, textCode });
    if (valid) setValidPhone(true);
    else setValidPhone(false);
    return <h1>Code processed.</h1>;
  };


  //#region register function
  const [registerSuccess, setRegisterSuccess] = React.useState({
    registerSuccessful: false,
    attempted: false,
  });

  const [loading, setLoading] = React.useState(false);

  const onRegister = async () => {
    if (!loading) {
      setLoading(true);
    const { username } = params;
    const usernameStatus = await usernameCheck( {username} );

    if (!usernameStatus) {
      setUsernameFree({
        //change this to trigger the username alert
        usernameTaken: true,
      });
      setLoading(false);
      return <h1>Username is taken.</h1>;
    } else {
      setUsernameFree({
        usernameTaken: false,
      });
    }

    if (params.password !== params.confirmPassword) {
      setSamePasswords({
        diffPass: true, //change this to trigger the password alert
      });
      setLoading(false);
      return <h1>Passwords do not match.</h1>;
    } else {
      setSamePasswords({
        diffPass: false,
      });
    }
    
    const payload = {...params};
    try {
    if (params.phoneNumber && params.phoneNumber.length > 3) {
      const phoneNumber = parsePhoneNumber(removeParentheses(params.phone)).number;
      if (isValidPhoneNumber(phoneNumber) && validPhone) {
        payload.phone = phoneNumber;
      } else {
        delete payload.phone;
      }
    } else {
      delete payload.phone;
    }
  } catch {
    delete payload.phone
  }
  
    const registerResult = await register( payload );
    setLoading(false);
    if (!registerResult) {
      // trigger error message
      setRegisterSuccess({registerSuccessful: false, attempted: true})
      console.log("FAILED")
      return <h1>Registration failed.</h1>;
    } else {
    // direct to explore page
    navigate("/explore/thankyou");
    setRegisterSuccess({registerSuccessful: true})
    }
  }
  };
  //# end region

  const [Email, EmailSet2] = React.useState({
    EmailEntered: false,
    EmailValidated: false,
  });

  const [validEmail, setValidEmail] = React.useState({
    emailValid: false,
  });

  const [validCode, setvalidCode] = React.useState({
    codeinValid: false,
  });

  const [samePasswords, setSamePasswords] = React.useState({
    diffPass: false,
  });

  const [usernameFree, setUsernameFree] = React.useState({
    usernameTaken: false,
  });



  /**
   * Renders the email input form.
   * @returns {JSX.Element} The rendered email input form.
   */
  function EnterEmail() {
    return (
      <div className="form">
        <form className="form-body">
          <BasicFormInput
            itemName={"email"}
            label={"Email"}
            value={params.email}
            onChange={setEmail}
            placeholder={"Enter email..."}
            autoComplete={"email"}
            valid = {/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(params.email)}
          />

          {Email.EmailEntered &&
            <BasicFormInput
            itemName={"code"}
            label={"Email Code"}
            value={params.emailCode}
            onChange={setEmailCode}
            type={"number"}
            placeholder={"Enter code..."}
            autoComplete={"one-time-code"}
            valid = {null}
          />}

          <div className="footer">
            
            {Email.EmailEntered ?
            <TextButton text="Verify" onClick={onEnterEmailCode} width={120} textSize="1.15rem"/>
              :
            <TextButton text="Next" onClick={onEnterEmail} width={120} textSize="1.15rem"/>
            }
          </div>
          {isErrorVisible &&
          <ConditionalMessage
            condition={validEmail.emailValid}
            message={"Invalid email type."}
            isAlert={true}
          />}
        </form>
      </div>
    );
  }

  /**
   * Opens a popup window to the App Store with the URL of Gravidy.
   */
  function openAppStorePopup() {
    // Replace with the actual URL of Gravidy in the App Store
    const appStoreUrl = APPSTORE_URL; 
    window.open(appStoreUrl, '_blank');
  }

  /**
   * Renders the last page in the registration form.
   * @returns {JSX.Element} The JSX element representing the complete registration form.
   */
  function CompleteRegistration() {
    return (
      <div className="form">
        <div className="form-body">
          <BasicFormInput
            itemName={"username"}
            label={"Username"}
            value={params.username}
            onChange={setUsername}
            type={"text"}
            placeholder={"username"}
            valid = {isValidUsername === 'Valid'}
          />

          <BasicFormInput
            itemName={"birthday"}
            label={"Birthday"}
            value={params.birthday}
            onChange={setBirthday}
            type={"date"}
            placeholder={""}
            autoComplete={'bday'}
            valid = {params.birthday}
          />

          <BasicFormInput
            itemName={"phone"}
            label={"Phone Number"}
            value={params.phone}
            onChange={setPhone}
            //type={"number"}
            placeholder={"Phone (optional)"}
            autoComplete={'tel'}
            valid = {isValidPhoneNumber(removeParentheses(params.phone)) || params.phone == "+1 "}
          />

          {isValidPhoneNumber(params.phone) ?  <BasicFormInput
            itemName={"code"}
            label={"Enter Code"}
            value={params.textCode}
            onChange={setTextCode}
            type={"number"}
            placeholder={"Enter text code..."}
            autoComplete={"one-time-code"}
            valid = {validPhone}
          /> : params.phone == "+1 "&& <div className="specialCode">(number optional)</div>}

          {isValidPhoneNumber(params.phone) && params.textCode?.length == 6 && !validPhone && <div className="specialCode">Phone number not yet verified.</div>}

          <BasicFormInput
            itemName={"password"}
            label={"Password"}
            value={params.password}
            onChange={setPassword}
            type={"password"}
            placeholder={"Enter password..."}
            autoComplete={'new-password'}
            valid = {isValidPassword}
          />
          <BasicFormInput
            itemName={"password"}
            label={"Confirm Password"}
            value={params.confirmPassword}
            onChange={setConfirmPassword}
            type={"password"}
            placeholder={"Confirm password..."}
            autoComplete={'new-password'}
            valid = {params.confirmPassword && params.password === params.confirmPassword}
          />
          {(!isValidPassword && params.password?.length > 3) && <div className="specialCode">Include at least 7 characters with an uppercase letter and numbers</div>}
          {isMobile && <Button onClick={openAppStorePopup}>Open App Store</Button>}

          {/* {validCode.codeinValid && <CheckCircleIcon sx={{ color: green[500] }}></CheckCircleIcon> } */}
          <div className="footer">
          <TextButton text="Create Account" onClick={onRegister} width={155} textSize="1.15rem" style={{opacity: loading ? 0.2 : 1}}/>
          </div>
        </div>

        <ConditionalMessage
          condition={usernameFree.usernameTaken}
          message={"Username is taken. Try a different username."}
          isAlert={true}
        />

        <ConditionalMessage
          condition={samePasswords.diffPass}
          message={"Passwords do not match."}
          isAlert={true}
        />

      <ConditionalMessage
          condition={!registerSuccess.registerSuccessful && registerSuccess.attempted}
          message={"Please fill out all fields."}
          isAlert={true}
        />

        {registerSuccess.registerSuccessful ? (
          <p
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              fontSize: "15px",
            }}>
            <a
              href={
                "https://www.youtube.com/watch?v=dQw4w9WgXcQ&pp=ygUJcmljayByb2x"
              }>
              Registered! Download our app and sign in.
            </a>
          </p>
        ) : null}
      </div>
    );
  }


  function ReturnFunc() {
    if (Email.EmailValidated) {
      return <FormControl>
      {username && <div className="specialCode"><b>{username}</b> invited you to be a high-tier follower on Gravidy!</div> }
      {CompleteRegistration()}
      </FormControl>;
    } else {
      return <FormControl>{username && <div className="specialCode"><b>{username}</b> invited you to be a high-tier follower on Gravidy!</div> }{EnterEmail()}</FormControl>;
    }

  }

  /**
   * Validates an email address.
   * @param {string} email - The email address to validate.
   * @returns {boolean} - True if the email is valid, false otherwise.
   */
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  //Helper that checks if password has at least one number, between 6-16 chars, 1 special char
  function validateStrongPassword(password) {
    // const re = /^(?=.*[\d])(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,20}$/
    // const re = /^(?=.*[\d])[\w!@#$%^&*]{6,20}$/
    const re = /^(?=.*[\d])(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*]{7,50}$/
    if (re.test(password)) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  }

  /**
   * Checks the 2FA code and calls the onEnterEmailCode function if the code is valid.
   * @param {string} code - The 2FA code to be checked.
   */
  function check2faEmail(code) {
    if (code && code.length === 6) {
      onEnterEmailCode(code);
    }
  }

  function check2faText(code) {
    if (code && code.length === 6) {
      onEnterTextCode(code);
    }
  }

  /**
   * Checks if the provided username is valid and available.
   * 
   * @param {string} username - The username to be checked.
   * @returns {Promise<void>} - A promise that resolves once the username check is complete.
   */
  async function checkUsername(username) {
    const re = /^[a-z0-9_\.]{6,20}$/;

    if (re.test(username)) {
      const usernameStatus = await usernameCheck( {username} );
      if (usernameStatus) {
        setUsernameStatus('Valid');
        return;
      } 
      setUsernameStatus('Taken');
    } else {
      setUsernameStatus('Not Valid');
    }
  }

  return (
    <div className="register-page">
      <NavBar></NavBar>
      <div className="register" data-aos="fade-up">
        <div className="registerForm">
          <div className="registerFormInner">
            <div className="registerLogo"><img src={GravidyLogoGIF} alt="Gravidy logo"/></div>
            <h1 className="registerHeading">
              {PRE_REGISTER_MODE ? "Reserve Your Place" : "Sign Up for Gravidy"}
            </h1>
            {ReturnFunc()}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Returns an image element based on the value and validity.
 * @param {any} value - The value to check.
 * @param {boolean} valid - The validity flag.
 * @returns {JSX.Element} - The image element.
 */
function checkmark(value, valid) {
  const styling = {position: "absolute", right: "10px", top: "10px"};

  if (value == null || value == undefined || value.length === 0|| valid == null || valid == undefined) {
    return ;
  } else if (valid) {
    return <img style={{}} src={Checkmark} alt="checkmark" className={"checkMark"}/>;
  } else {
    return <img style={{}} src={Xmark} alt="x_mark" className={"checkMark"}/>;
  }
}

/**
 * Renders a basic form input component.
 * @param {boolean} props.valid - Indicates if the input is valid.
 * @returns {JSX.Element} The rendered BasicFormInput component.
 */
export const BasicFormInput = ({
  itemName,
  label,
  onChange,
  placeholder = "",
  value = "",
  type = "text",
  autoComplete,
  className,
  valid,
}) => {
  return (
      <Box className="formInputLabel" >
      <TextField
        // borderRadius="20%"
        style={{background:"white", borderRadius: "10px" }}
        onChange={onChange}
        className= {className ?? "form_input"}
        sx={{  }}
        size="small"
        type={type}
        id={itemName}
        value={value}
        name={itemName}
        placeholder={placeholder}
        required={true}
        autoComplete={autoComplete}
      ></TextField>
      {/* {valid ?  <img src={Checkmark} alt="checkmark" className={"checkMark"}/> :  <img src={Xmark} alt="x_mark" className={"checkMark"}/>} */}
      {checkmark(value, valid)}
    </Box>
  );
};

/**
 * Renders a conditional message based on the provided condition.
 *
 * @param {boolean} props.condition - The condition to evaluate.
 * @param {string} props.message - The message to display.
 * @param {boolean} [props.isAlert=false] - Indicates if the message should be styled as an alert.
 * @returns {JSX.Element|null} The rendered conditional message or null if the condition is false.
 */
const ConditionalMessage = ({ condition, message, isAlert = false }) => {
  // can configure appearance if isAlert is true (make it red or smth)
  // can later make it fade / or animate in
  return (
    <>
      {condition ? (
        <p
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            fontSize: "15px",
            color: isAlert ? "red" : "black",
          }}>
          {message}
        </p>
      ) : null}
    </>
  );
};

//#region handling phone number input
const addParentheses = (text) => {
  const splitBySpace = text.split(" ");
  if (splitBySpace.length <= 1) {
    return text;
  }
  if (splitBySpace.length == 2) {
    return splitBySpace[0] + " (" + splitBySpace[1];
  } else if (splitBySpace.length == 3) {
    return splitBySpace[0] + " (" + splitBySpace[1] + ") " + splitBySpace[2];
  } else if (splitBySpace.length > 3) {
    return (
      addParentheses(splitBySpace.slice(0, 3).join(" ")) +
      " " +
      splitBySpace.slice(3).join(" ")
    );
  }
};

//reverse the above function
export const removeParentheses = (text) => {
  const splitBySpace = text.split(" ");
  if (splitBySpace.length <= 1) {
    return text;
  }
  if (splitBySpace.length == 2 && splitBySpace[1].length != 1) {
    return splitBySpace[0] + splitBySpace[1].substring(1);
  } else if (splitBySpace.length >= 3) {
    return splitBySpace.join(" ").replace(")", "");
  }
  return text;
};

export const transformValue = (text) => {
  return text != ""
    ? addParentheses(new AsYouType().input(removeParentheses(text)))
    : "+";
};
//#endregion