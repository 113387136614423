import * as React from "react";
import { Theme } from "../theme";
import { Routes, Route, useParams } from "react-router-dom";
import { CDN_URL } from "../context/CDN_URL";
import { useSiteContext } from "../context/siteContext";
// import CreateAcc from "../assets/images/Group 6.png";
import { NavBar } from "../Components/navbar/navbar";
import { LinkButtonWrapper } from "../Components/LinkButtonWrapper";
import { UserProfile } from "../Components/UserProfile/UserProfile";

export const SharePage = () => {
  // TEST LINK: /share/4f67aff6-3100-424c-a28b-c34ac62c40db/105c7ec2cf6bb0fc36df474299843a38ce29d1f7/sharerID

  // #region DATA COLLECTION STUFF
  // getting the creator invite "i" from the URL
  const { postID, creatorID, sharerID } = useParams();
  const [name, setName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const { getUsername } = useSiteContext();
  const setNames = async () => {
    const { name, username } = await getUsername({userID: creatorID});
    setName(name);
    setUsername(username);
  };

  React.useEffect(() => {
    setNames();
  }, []);

  // #endregion

  // #region VIDEO PLAYER STUFF
  const [muted, setMuted] = React.useState(true);
  const handleToggleMute = () => setMuted(current => !current);
  const uri = CDN_URL + `videos/${creatorID}/${postID}.mp4`;


  // #endregion

  return (
    <div>
      <NavBar></NavBar>
      <div style={{ backgroundColor: "black" }}>
        <div
          height="100%"
          style={{
            display: "flex",
            flexDirection: "column",
            //backgroundColor: "rgba(255,1,1,.5)",
            alignItems: "center",
          }}>
          <div style={{ paddingTop: 10, borderRadius: 5 }}>
            <video
              src={uri}
              style={{ width: "240px", height: "420px", objectFit: "cover" }}
              autoPlay={true}
              loop={true}
              muted={muted}
            />
            <button onClick={handleToggleMute} className="control">{muted ? 'Unmute' : 'Mute'}</button>
          </div>

          <div
            style={{
              width: "100%",
              height: "100vh",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
            }}>
            <h1
              style={{
                color: "white",
              }}>
              You're viewing a video by @{username}
            </h1>
            
            {sharerID != null ? (
              <UserProfile userID={sharerID} message="shared with you!" />
            ) : (
              <></>
            )}

            <h1 style={{ color: "white" }}>Want to see more?</h1>
            <LinkButtonWrapper href="/register">
              {/* <img
                src={CreateAcc}
                alt="Create an account"
                style={{ color: "white", width: "100%" }}
              /> */}
            </LinkButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
