import * as React from "react";
import { Routes, Route, BrowserRouter, redirect } from "react-router-dom";
import {
  RegisterPage,
  SharePage,
  TOSPage,
  PrivacyPolicyPage,
  CommunityGuidelinesPage,
  HomePage,
  CreatorPage,
} from "./pages/index";
import { SiteProvider } from "./context/siteContext";
import LoadingScreen from "./Components/LoadingScreen";
import rg4js from 'raygun4js';
import { ExplorePage } from "./pages/explore/explore";
import { CreatorParallaxPage } from "./pages/creatorparallax/creatorparallax"
import { EULAPage } from "./pages/eula";

const api_key = process.env.REACT_APP_TRACKING_API;

rg4js('apiKey', api_key);
rg4js('enablePulse', true);
rg4js('enableCrashReporting', true);

export const CREATOR_SIGNUP_ENDPOINT = "/creator"
export const CREATOR_INFO_ENDPOINT = "/creatorinfo" // /creatorinfo

function App() {
  const [loading, setLoading] = React.useState(true);

  const handleLoadingComplete = () => {
    setLoading(false);
  };
  
  return (
    <SiteProvider>
    <meta charset="utf-8" name="viewport" content="width=device-width, height=device-height, initial-scale=1.0" />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
          <>
            <HomePage />      
            {/* {loading && <LoadingScreen onLoadingComplete={handleLoadingComplete} />} */}
          </>} />
          <Route path="/register/:username" element={<RegisterPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/explore/" element={<ExplorePage/>} />
          <Route path="/explore/:variant" element={<ExplorePage/>} />
          <Route path="/share/:creatorID/:postID" element={<SharePage />} />
          <Route path="/tos" element={<TOSPage />} />
          <Route path={CREATOR_SIGNUP_ENDPOINT} element={<CreatorPage />} />
          <Route path={CREATOR_INFO_ENDPOINT} element={<CreatorParallaxPage />} />
          <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
          <Route path="/eula" element={<EULAPage />} />
          <Route
            path="/communityguidelines"
            element={<CommunityGuidelinesPage />}
          />
          <Route path="*" element={<div></div>} /> 
          </Routes>
      </BrowserRouter>
    </SiteProvider>
  );
}

export default App;
