// import AnimatedLogo from '../assets/videos/Gravidy_Logo.webm';
import * as React from "react";
import { motion } from 'framer-motion';
import './LoadingScreen.css';

const LoadingScreen = ({ onLoadingComplete }) => {
  return (
    <motion.div
      className="loading-container"
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 2, ease: "easeInOut", delay:7}}
      onAnimationComplete={onLoadingComplete}
    >
      <video autoPlay loop muted playsInline>
        <source src={""} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </motion.div>
  );
};

export default LoadingScreen;

