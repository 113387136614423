import * as React from "react";
import { NavBar } from "../../Components/navbar/navbar";
import { Spoiler } from "../../Components/Spoiler/Spoiler.js"
import "./tos.css"


export const TOSPage = () => {
  return <div className="tospage">
    <NavBar color="black"/>
    <div className="tos" data-aos="fade-down">
        <h1> Terms of Service </h1>
        <p dir="ltr">
        Last updated: August 20, 2023
        </p>

        <Spoiler className="spoiler" title="About Us">
            <p dir="ltr">
                Gravity Platforms is a Social Entertainment Application. Our company began
                with a core objective: counteracting the issues of social Services that have
                come to prominence in the 21st century. Unpredictable algorithms drive the
                issues that plague us in the modern information age- everything from
                negative content spirals, to addiction, to echo chambers. The necessary
                solutions simply will not come from established players. These companies
                suffer from corporate inertia in addressing any problems meaningfully
                because their profit revenues are heavily tied to the very issues they
                fostered. The solution must be an alternative, one that we are creating. At
                Gravity Platforms, we can solve this with higher-quality feedback directly
                from the users, who can signal their real thoughts via a novel &amp;
                intuitive UI that integrates nuanced ratings with content navigation.
                Integral to this service, THE TERMS OF SERVICE BELOW GOVERNS YOUR USE OF
                GRAVIDY AND ALL OTHER GRAVITY PLATFORMS ONLINE SERVICES.
            </p>

            <p dir="ltr">
                The <a href="https://www.gravidy.app/privacypolicy">Privacy Policy</a>, Terms of
                Service, and{" "}<a href="https://www.gravidy.app/communityguidelines">Community Guidelines</a> are mutually incorporated. Please read all
                in their entirety. Your continued use of Gravity Platforms services implies
                your consent and acceptance of all agreements. Gravidy is offered to you
                conditioned on your acceptance without modification of the terms,
                conditions, and notices contained herein (the "Terms"). Your use of Gravity
                Platforms constitutes your agreement to all such Terms. Please read these
                terms carefully, and keep a copy of them for your reference.
            </p>
        </Spoiler>
        
        <Spoiler className="spoiler" title="Interpretation and Definitions">
            <h3> Interpretation </h3>
            <p dir="ltr">
                The words of which the initial letter is capitalized have meanings defined
                under the following conditions. The following definitions shall have the
                same meaning regardless of whether they appear in singular or in plural.
            </p>

            <h3> Definitions </h3>
            <p dir="ltr">
                For the purposes of these Terms of Service:
            </p>
            <p dir="ltr">
                <strong>Account</strong> means a unique account created for you to access our Service or
                parts of our Service.
            </p>
            <p dir="ltr">
                <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common
                control with a party, where "control" means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for election of
                directors or other managing authority.
            </p>
            <p dir="ltr">
                <strong>Application</strong> or <strong>Platform</strong> refers to any software program provided by the
                Company (the Gravidy mobile application and the gravidy.app website
                application).
            </p>
            <p dir="ltr">
                <strong>Company</strong> (referred to as either "Gravity Platforms", "we", "us" or "our" in
                this Agreement) refers to Gravity Platforms.
            </p>
            <p dir="ltr">
                <strong>Content</strong> is users' published posts on the Platform, including comments
            </p>
            <p dir="ltr">
                <strong>Device</strong> means any device that can access the Service such as a computer, a
                cellphone or a digital tablet.
            </p>
            <p dir="ltr">
                <strong>Personal Data</strong> is any information that relates to an identified or
                identifiable individual.
            </p>
            <p dir="ltr">
                <strong>Service Provider</strong> means any natural or legal person who processes the data on
                behalf of the Company. It refers to third-party companies or individuals
                employed by the Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the Service or to
                assist the Company in analyzing how the Service is used.
            </p>
            <p dir="ltr">
                <strong>Stakeholder</strong> refers to the Company mission's three main stakeholders: users,
                creators, and advertisers.
            </p>
            <p dir="ltr">
                <strong>Stakeholder Content</strong> is what the Account holder posts to the Services
            </p>
            <p dir="ltr">
                <strong>Third-party Service</strong> refers to any website or any social network website
                through which a user can log in or create an account to use the Service.
            </p>
            <p dir="ltr">
                <strong>Usage Data</strong> refers to data collected automatically, either generated by the
                use of the Service or from the Service infrastructure itself (for example,
                the duration of a page visit).
            </p>
        </Spoiler>

        <Spoiler className="spoiler" title="Your Account and Safety">
            <p dir="ltr">
                Account
            </p>
            <p dir="ltr">
                If you use any of our Services, you are responsible for maintaining the
                confidentiality of your account and password and for restricting access to
                your devices, and you agree to accept responsibility for all activities that
                occur under your account or password. You may not assign or otherwise
                transfer your account to any other person or entity. You acknowledge that
                Gravity Platforms is not responsible for third party access to your account
                that results from theft or misappropriation of your account. Gravity
                Platforms and its associates reserve the right to refuse or cancel service,
                terminate accounts, or remove or edit content in our sole discretion.
            </p>
            <br/>
            <p dir="ltr">
                Age Restrictions
            </p>
            <p dir="ltr">
                Our Service is offered to everyone ages 18 and above. We caution
                restrictions around the accounts of users under 18, and do not support users
                under age 16. Please refer to our Privacy Policy for further information.
            </p>
            <br/>
            <p dir="ltr">
                Electronic Communications
            </p>
            <p dir="ltr">
                Using Gravity Platforms' Services or sending communications to Gravity
                Platforms constitutes electronic communications. You consent to receive
                electronic communications and you agree that all agreements, notices,
                disclosures and other communications that we provide to you electronically,
                via email and on Gravidy, satisfy any legal requirement that such
                communications be in writing.
            </p>
            <br/>
            <br/>
            <p dir="ltr">
                Location-Based Services
            </p>
            <p dir="ltr">
                To the maximum extent permitted by law, this agreement is governed by the
                laws of your resident state and you hereby consent to the exclusive
                jurisdiction and venue of courts in your state in all disputes arising out
                of or relating to the use of our Platform. Use of our Platform is
                unauthorized in any jurisdiction that does not give effect to all provisions
                of these Terms, including, without limitation, this section.
            </p>
            <br/>
            <p dir="ltr">
                California Consumer Rights Notice
            </p>
            <p dir="ltr">
                Under California Civil Code Section 1789.3, California users of the Services
                receive the following specific consumer rights notice: The Complaint
                Assistance Unit of the Division of Consumer Services of the California
                Department of Consumer Affairs may be contacted in writing at the contact
                information set forth at
                <a href="https://www.dca.ca.gov/about_us/contactus.shtml">
                    https://www.dca.ca.gov/about_us/contactus.shtml
                </a>
                . Stakeholders of the Services who are California residents and are under 18
                years of age may request and obtain removal of Stakeholder Content they
                posted by contacting us at: <a>legal@gravityplatforms.com</a>. All requests
                must be labeled "California Removal Request" on the email subject line. All
                requests must provide a description of the Stakeholder Content you want
                removed and information reasonably sufficient to permit us to locate that
                Stakeholder Content. We do not accept California Removal Requests via postal
                mail, telephone or facsimile. We are not responsible for notices that are
                not labeled or sent properly, and we may not be able to respond if you do
                not provide adequate information.
            </p>

        </Spoiler>

        <Spoiler title="User Generated Content">
            <p dir="ltr">
                The Services may invite you or provide you with the opportunity to post,
                comment, view Content, or other functionality (collectively,
                "Contributions") in the Application. When you create or make available any
                Contributions, you thereby represent and warrant that:
            </p>
            <ol>
                <li dir="ltr">
                    <p dir="ltr">
                        The creation, distribution, transmission, public display, or
                        performance, and the accessing, downloading, or copying of your
                        Contributions do not and will not infringe the proprietary rights,
                        including but not limited to the copyright, patent, trademark, trade
                        secret, or moral rights of any third party.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        You are the creator and owner of or have the necessary licenses,
                        rights, consents, release, and permissions to use and to authorize
                        us, the Application, and other users of the Application to use your
                        Contributions in any manner contemplated by the Application and this
                        agreement.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions are not false, inaccurate, or misleading.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions are not unsolicited or unauthorized advertising,
                        promotional materials, pyramid schemes, chain letters, spam, mass
                        mailings, or other forms of solicitation.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions are not obscene, lewd, lascivious, filthy,
                        violent, harassing, libelous, slanderous, or otherwise objectionable
                        (as determined by Gravity Platforms).
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions do not ridicule, mock, disparage, intimidate, or
                        abuse anyone.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions are not used to harass or threaten (in the legal
                        sense of those terms) any other person and to promote violence
                        against a specific person or class of people.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions do not violate any applicable law, regulation, or
                        rule.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions do not violate the privacy or publicity rights of
                        any third party.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions do not violate any applicable law concerning
                        child pornography, or otherwise intended to protect the health or
                        well-being of minors.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions do not include any offensive comments that are
                        connected to race, national origin, gender, sexual preference, or
                        physical handicap.
                    </p>
                </li>
                <li dir="ltr">
                    <p dir="ltr">
                        Your Contributions do not otherwise violate, or link to material
                        that violates, any provision of this Terms of Service agreement,
                        other agreements made with Gravity Platforms, or any applicable law
                        or regulation.
                    </p>
                </li>
            </ol>
        </Spoiler>
        
        <Spoiler title="Intellectual Property">
            <p dir="ltr">
                Intellectual Property
            </p>
            <br/>
            <p dir="ltr">
                No Unlawful or Prohibited Use/Intellectual Property
            </p>
            <p dir="ltr">
                You are granted a non-exclusive, non-transferable, revocable license to
                access and use Gravity Platforms strictly in accordance with these Terms of
                Service. As a condition of your use of Gravidy, you warrant to Gravity
                Platforms that you will not use Gravidy for any purpose that is unlawful or
                prohibited by these Terms. You may not use Gravidy in any manner which could
                damage, disable, overburden, or impair Gravidy or interfere with any other
                party's use and enjoyment of Gravidy. You may not obtain or attempt to
                obtain any materials or information through any means not intentionally made
                available or provided for through Gravidy.
            </p>
            <p dir="ltr">
                All content included as part of the Service, such as text, graphics, logos,
                images, as well as the compilation thereof, and any software used on
                Gravidy, is the property of Gravity Platforms or its suppliers and protected
                by copyright and other laws that protect intellectual property and
                proprietary rights. You agree to observe and abide by all copyright and
                other proprietary notices, legends or other restrictions contained in any
                such content and will not make any changes thereto.
            </p>
            <p dir="ltr">
                You will not modify, publish, transmit, reverse engineer, participate in the
                transfer or sale, create derivative works, or in any way exploit any of the
                content, in whole or in part, found on Gravidy. Gravity Platforms content is
                not for resale. Your use of Gravidy does not entitle you to make any
                unauthorized use of any protected content, and in particular you will not
                delete or alter any proprietary rights or attribution notices in any
                content. You will use protected content solely for your personal use, and
                will make no other use of the content without the express written permission
                of Gravity Platforms and the copyright owner. You agree that you do not
                acquire any ownership rights in any protected content. We do not grant you
                any licenses, express or implied, to the intellectual property of Gravity
                Platforms or our licensors except as expressly authorized by these Terms.
            </p>
            <p dir="ltr">
                Gravity Platforms does not claim ownership of the materials you provide to
                Gravity Platforms (including feedback and suggestions) or post, upload,
                input or submit to any Gravity Platforms Application or our associated
                Services. However, by posting, uploading, inputting, providing or submitting
                your Services you are granting Gravity Platforms, our affiliated companies
                and necessary sublicensees permission to use your Services in connection
                with the operation of their Internet businesses including, without
                limitation, the rights to: copy, distribute, transmit, publicly display,
                publicly perform, reproduce, edit, translate and reformat your Services; and
                to publish your name in connection with your Content.
            </p>
            <br/>
            <p dir="ltr">
                No compensation will be paid with respect to the use of your Services, as
                provided herein. Gravity Platforms is under no obligation to post or use any
                Content you may provide and may remove any Content at any time in Gravity
                Platforms' sole discretion. By posting, uploading, inputting, providing or
                submitting your Services you warrant and represent that you own or otherwise
                control all of the rights to your Services as described in this section
                including, without limitation, all the rights necessary for you to provide,
                post, upload, input or submit the Services.
            </p>
        </Spoiler>
        
        <Spoiler title="Termination/Access Restriction">
            <p dir="ltr">
                In the future, if you would no longer like to use our Platform, you can
                submit your request in-app. There, you will have the ability to permanently
                delete your account across all our Platforms. Gravity Platforms reserves the
                right, in its sole discretion, to terminate your access to our Platform and
                the related services or any portion thereof at any time, without notice. You
                agree that no joint venture, partnership, employment, or agency relationship
                exists between you and Gravity Platforms as a result of this agreement or
                use of our Platform. Gravity Platforms' performance of this agreement is
                subject to existing laws and legal process, and nothing contained in this
                agreement is in derogation of Gravity Platforms's right to comply with
                governmental, court and law enforcement requests or requirements relating to
                your use of our Platform or information provided to or gathered by Gravity
                Platforms with respect to such use. If any part of this agreement is
                determined to be invalid or unenforceable pursuant to applicable law
                including, but not limited to, the warranty disclaimers and liability
                limitations set forth above, then the invalid or unenforceable provision
                will be deemed superseded by a valid, enforceable provision that most
                closely matches the intent of the original provision and the remainder of
                the agreement shall continue in effect.
            </p>
        </Spoiler>
        
        <Spoiler title="Third-party Accounts">
            <p dir="ltr">
                If you connect your Gravity Platforms account to Third-party accounts, you
                acknowledge and agree that you are consenting to the continuous release of
                information about you to others (in accordance with your privacy settings on
                those third party services). If you do not want information about you to be
                shared in this manner, do not use this feature.
            </p>
        </Spoiler>

        <Spoiler title="Your Rights">
            <p dir="ltr">
                Indemnification
            </p>
            <p dir="ltr">
                You agree to indemnify, defend and hold harmless Gravity Platforms, its
                officers, directors, employees, agents and third parties, for any losses,
                costs, liabilities and expenses (including reasonable attorney's fees)
                relating to or arising out of your use of or inability to use Gravidy or
                services, any user postings made by you, your violation of any terms of this
                Agreement or your violation of any rights of a third party, or your
                violation of any applicable laws, rules or regulations. Gravity Platforms
                reserves the right, at its own cost, to assume the exclusive defense and
                control of any matter otherwise subject to indemnification by you, in which
                event you will fully cooperate with Gravity Platforms in asserting any
                available defenses.
            </p>
            <p dir="ltr">
                Arbitration
            </p>
            <p dir="ltr">
                In the event the parties are not able to resolve any dispute between them
                arising out of or concerning these Terms of Service, or any provisions
                hereof, whether in contract, tort, or otherwise at law or in equity for
                damages or any other relief, then such dispute shall be resolved only by
                final and binding arbitration pursuant to the Federal Arbitration Act,
                conducted by a single neutral arbitrator and administered by the American
                Arbitration Association, or a similar arbitration service selected by the
                parties, in a location mutually agreed upon by the parties. The arbitrator's
                award shall be final, and judgment may be entered upon it in any court
                having jurisdiction. In the event that any legal or equitable action,
                proceeding or arbitration arises out of or concerns these Terms of Service,
                the prevailing party shall be entitled to recover its costs and reasonable
                attorney's fees. The parties agree to arbitrate all disputes and claims in
                regards to these Terms of Service or any disputes arising as a result of
                these Terms of Service, whether directly or indirectly, including Tort
                claims that are a result of these Terms of Service. The parties agree that
                the Federal Arbitration Act governs the interpretation and enforcement of
                this provision. The entire dispute, including the scope and enforceability
                of this arbitration provision shall be determined by the Arbitrator. This
                arbitration provision shall survive the termination of these Terms of
                Service.
            </p>
        </Spoiler>
        
        <Spoiler title="Class Action Waiver">
            <p dir="ltr">
                ANY ARBITRATION UNDER THESE Terms of Service WILL TAKE PLACE ON AN
                INDIVIDUAL BASIS; class arbitrations and class/representative/collective
                actions are not permitted. The parties agree that a party may bring claims
                against the other only in each's individual capacity, and not as a plaintiff
                or class member in any putative class, collective, and/or representative
                proceeding, such as in the form of a private attorney general action against
                the other. Further, unless both you and Gravity Platforms agree otherwise,
                the arbitrator may not consolidate more than one person's claims, and may
                not otherwise preside over any form of a representative or class proceeding.
            </p>
        </Spoiler>

        <Spoiler title="Liability Disclaimer">
            <p dir="ltr">
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE
                THROUGH THE PLATFORM SERVICES MAY INCLUDE INACCURACIES OR ERRORS. Changes
                are periodically made to update the information herein. Gravity Platforms
                and/or its suppliers may make improvements and/or changes in the platform
                services at any time.
            </p>
        </Spoiler>
    
        <Spoiler title="Extra/Misc">
            <p dir="ltr">
                Gravity Platforms and/or its suppliers make no representations about the
                suitability, reliability, timeliness, and accuracy of the information,
                software, products, services, and related graphics contained on the Platform
                for any purpose. To the maximum extent permitted by applicable law, all such
                information, software, products, services, and related graphics are provided
                "as is" without warranty or condition of any kind. Gravity Platforms and/or
                its suppliers hereby disclaim all warranties and conditions with regard to
                this information, software, products, services, and related graphics,
                including all implied warranties or conditions of merchantability, fitness
                for a particular purpose, title, and non-infringement.
            </p>
            <p dir="ltr">
                To the maximum extent permitted by applicable law, in no event shall Gravity
                Platforms and/or its suppliers be liable for any direct, indirect, punitive,
                incidental, special, consequential damages or any damages whatsoever,
                including, without limitation, damages for loss of use, data or profits,
                arising out of or in any way connected with the use or performance of the
                Platform, with the delay or inability to use the Platform or related
                services, the provision of or failure to provide services, or for any
                information, software, products, and related graphics obtained through the
                Platform, whether based on contract, tort, negligence, strict liability, or
                otherwise, even if Gravity Platforms Incorporated or any of its suppliers
                has been advised of the possibility of damages. BECAUSE SOME
                STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
                FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY
                TO YOU. While we will do our best to work with your individual case, if you
                are dissatisfied with any portion of the Platform, or with any of these
                Terms of Service, your critical remedy is to discontinue using the Platform.
            </p>
            <br/>
            <p dir="ltr">
                Changes to these Terms of Service
            </p>
            <p dir="ltr">
                Gravity Platforms reserves the right to change the Terms under which our
                Services are offered. The most current version of the Terms will supersede
                all previous versions. Gravity Platforms encourages you to periodically
                review the Terms. We will notify you of any changes by posting the new Terms
                on this page.
            </p>
            <p dir="ltr">
                We will let you know via email and/or a prominent notice on our Service,
                prior to the change becoming effective and update the "Last updated" date at
                the top of these Terms.
            </p>
            <br/>
            
        </Spoiler>

        <Spoiler title="Contact Us">
            <p dir="ltr">
                If you have any questions about this Terms of Service agreement, you can
                contact us at{" "}
                    <a href="mailto:legal@gravityplatforms.com">legal@gravityplatforms.com</a>.
            </p>
        </Spoiler>
        
        </div>
    </div>;
};
