import React, {useState} from 'react';
import { Routes, Route, useParams, Link, useNavigate } from "react-router-dom";
import { useSiteContext } from "../../context/siteContext.js";
import './creator.css';
import GravidyLogoGIF from "../../assets/images/GravidyLogoGIF.webp";
import { LigherTextButton, TextButton } from '../../Components/Button.js';
import Circle from "./Circle.js";
import { NavBar } from '../../Components/navbar/navbar.js';

import Parallax1 from '../../assets/images/high quality/parallax_1.webp';
import { BasicFormInput } from '../register/register.js';

/**
 * A page that is the creator sign-up form, needs to collect the following information for payload
 *      username : str
 *      usernameAvailable : bool
 *      email : str
 *      verificationCode : int
 *      emailVerified : bool
 *      handles: dict[<platform>, <handle>]
 *      acceptedTransfer : bool 
 * 
 * At the end it displays to them their password for when they want to log in & explains this process + the creator pilot invite system
 * The info should also be emailed. 
 * @returns 
 */
export const CreatorPage = () => {
    const Navigate = useNavigate();
    const { email2fa, email2faCheck, usernameCheck, uploadCreatorForm } = useSiteContext();
    const [card, setCard] = useState(1);
    const randomPassword = Math.random().toString(36).slice(-6).toUpperCase();
    const [payload, setPayload] = useState({handles: {}, password: randomPassword, username: "", email: "", emailCode: ""});
    const [nameAvail, setNameAvail] = useState(undefined);
    const [emailVerified, setEmailVerified] = useState(undefined);
    const [validator, setValidator] = useState({
        username: (value) => {

        },
    });


    const updateValidator = (key, fn) => {
        setValidator(prev => ({...prev, [key]: fn}));

        //debug
        console.log(validator);
    }

    const validateField = (field, value) => {
        if (validator[field]) {
            return validator[field](value);
        } else {
            console.log(`No validator for field: ${field}`);
            return "";
        }
    }

    const updatePayload = (key, value) => {
        setPayload({...payload, [key]: value});
    }

    const goToCard = (cardNum) => {
        setCard(cardNum);
    }

    const onClickBack = () => {
        if (card > 1) {
            goToCard(card - 1);
        } else {
            Navigate("/");
        }
     }

    const sendVerificationEmail = async () => {
        // handle invalid email
        const res = await email2fa({email: payload.email});
        return res;
    }

    const checkVerificationEmail = async (code) => {
        // handle invalid code
        const goodToGo = await email2faCheck({email: payload.email, emailCode: code});
        setEmailVerified(goodToGo);
        return goodToGo;
    }

    const updatePlatformHandle = (platform, handle) => {
        setPayload(prev => ({...prev, handles: {...payload.handles, [platform]: handle}}));

        //debug
        console.log(payload);
    }
    const [error, setError] = useState(false);
    const onSubmitForm = async ({setLoading = ()=>{}}) => {
        // handle invalid handle
        if (emailVerified && nameAvail) {
            setLoading(true);
        const res = await uploadCreatorForm(payload);
        if (!res) {
            setError(true);
        } else {
            setError(false);
            Navigate("/explore/thankyou")
        }
        } else {
            // handle invalid email / username
        }
        setLoading(false);
    }

    /**
     * Can be called each time after the user enters a letter for username
     */
    const checkUsernameAvailability = async (username) => {
        // handle invalid username
        if (username.length >= 3) {
        const goodToGo = await usernameCheck({username});
        setNameAvail(goodToGo);
        } else {
            setNameAvail(undefined);
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter"){
            e.preventDefault();
            goToCard(card + 1);
        }
    }

    return (
        <div id="creator-body" onKeyDown={handleKeyPress}>
            <NavBar id="creator-nav"/>
 
            <div id="creator-body-bottom" data-aos="fade-up">
            
                <div id="creator-card">
                <LogoCard 
                    title="Creator Pilot Program" 
                    desc=""
                    goToCard={goToCard}
                    card={card}
                    className="logo-card"
                />
                <div id="creator-back" onClick={onClickBack}>&#8592;</div>
                
                {
                {
                1: <Card1 
                    updatePayload={updatePayload} 
                    nameAvail={nameAvail}
                    setNameAvail={setNameAvail}
                    checkUsernameAvailability={checkUsernameAvailability} 
                    goToCard={goToCard}
                    username={payload.username}
                    />, 
                2: <Card2 
                    updatePayload={updatePayload} 
                    goToCard={goToCard} 
                    email={payload.email}
                    sendVerificationEmail={sendVerificationEmail}
                    />,
                3: <Card3
                    payload={payload}
                    updatePayload={updatePayload}
                    goToCard={goToCard}
                    emailVerified={emailVerified}
                    setEmailVerified={setEmailVerified}
                    checkVerificationEmail={checkVerificationEmail}
                />,
                4: <Card4 
                    updatePayload={updatePayload} 
                    updatePlatformHandle={updatePlatformHandle}
                    goToCard={goToCard}
                    payload={payload}
                    />,
                5: <Card5 
                    updatePayload={updatePayload} 
                    goToCard={goToCard}
                    payload={payload}
                    onSubmitForm={onSubmitForm}
                    error={error}
                    />
                }[card]
                }
                </div>
            </div>
        </div>
    );
}

const LogoCard = ({title, desc, goToCard, className, card}) => { 
    return (
        <div style={{flexDirection: "column"}} className={className}>
            <img 
                id="creator-img" 
                className="undraggable"
                src={GravidyLogoGIF} 
                alt="creator"
                onClick ={() => null}
            />
            <h1 id="creator-h1">Creator Pilot</h1>
            {card ==1 ? <> <img id="distribution-img" src={Parallax1}></img>
            <p id="creator-desc"><Link to="/explore/form" id="creator-desc">{"Get exclusive creator access. Explore Gravidy here →"}</Link></p></> : null}

        </div>
    );
} 

/**
 * 
 * @param {*} updatePayload - function to update the payload
 * @param {*} checkUsernameAvailability - function to check if the username is available
 * @param {*} goToCard - function to go to the next card
 * @param {*} username - the username the user has typed in
 * @returns - the first card of the creator sign-up form
 */
const Card1 = ({updatePayload, checkUsernameAvailability, goToCard, username, nameAvail, setNameAvail}) => {

    const onTypeUsername = async ({nativeEvent: {target: {value} }}) => {
        updatePayload("username", value);
        if (value?.length >=4 ) {
        await checkUsernameAvailability(value);
        } else {
            setNameAvail(undefined);
        }
    }

    const onSubmitForm = async (e) => {
        if (nameAvail)
        goToCard(2);
    }

    const removeBadCharacters = (e) => {
        if (/[^a-zA-Z0-9.-]/g.test(e.key)) {
            e.preventDefault();
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmitForm();
        }
    }



    return (
        <>  
            <div class="creator-form" onKeyDown={handleKeyPress}>
                <h1 id="form-h1">{nameAvail === false ? "Username is Taken" : "Create Your Platform"}</h1>
                {/* <p id="form-p">This is for your account –– we will not spam promotions.</p> */}
                <BasicFormInput
                    itemName={"username"}
                    label={"Username"}
                    className="creator-input"
                    value={username ?? ''}
                    onChange={onTypeUsername}
                    type={"text"}
                    placeholder={"Reserve Username..."}
                    valid = {null}
                />
                {/* <input 
                    class="creator-input" 
                    type="text" 
                    name="username" 
                    placeholder="Username" 
                    onChange={onTypeUsername}
                    value={username || ''}
                    onKeyDown={(e) => removeBadCharacters(e)}
                    /> */}
                    
                <div id="verify-div" >
                    <LigherTextButton style={{opacity: !nameAvail ? 0.3 : 1}} text="Continue" onClick={onSubmitForm} />
                </div>
            </div>
        </>
    );
}

const Card2 = ({updatePayload, goToCard, sendVerificationEmail, email}) => {
    const [loading, setLoading] = useState(false);

    const onTypeEmail = (e) => {
        updatePayload("email", e.target.value);
    }

    const onSubmitForm = async (e) => {
        setLoading(true);
        const res = await sendVerificationEmail();
        if (res)
        goToCard(3);
        else {
            setLoading(false);
        }
    }

    /**
     * Validates an email address.
     * @param {string} email - The email address to validate.
     * @returns {boolean} - True if the email is valid, false otherwise.
     */
    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    return (
        <>
            
            <div class="creator-form">
                <p id="form-p">This is for your account ––<br/> we will not spam promotions.</p>
                <input 
                    class="creator-input" 
                    type="email" 
                    name="email" 
                    placeholder="Enter email..." 
                    onChange={onTypeEmail}
                    />
                
                <div id="verify-div">
                    <LigherTextButton style={{opacity: !validateEmail(email) || loading ? 0.3 : 1}} text="Continue" onClick={onSubmitForm} />
                </div>
            </div>
        </>
    );
}

const Card3 = ({payload, updatePayload, goToCard, checkVerificationEmail, emailVerified, setEmailVerified}) => {

    const onTypeVerificationCode = async (e) => {
        if (e.target.value.length > 6) return;
        else if (e.target.value.length < 6) return;
        setEmailVerified(e.target.value);
        updatePayload("emailCode", e.target.value)
        const good = await checkVerificationEmail(e.target.value);
        if (good) {
            goToCard(4);
        }
    }

    const onSubmitForm = (e) => {
        if (emailVerified) goToCard(4);
    }

    return (
        <>
        
            <div class="creator-form">
            <p id="form-p">{payload.email}</p>
                <input 
                        class="creator-input" 
                        type="number" 
                        name="verificationCode" 
                        placeholder="Verification Code" 
                        onChange={onTypeVerificationCode}/>
                <div id="verify-div">
                    <LigherTextButton text="Verify" onClick={onSubmitForm} />
                </div>
            </div>
        </>
    );
}

const Card4 = ({updatePayload, goToCard, updatePlatformHandle, payload}) => {

    const [selectedId, setSelectedId] = useState(0);

    const selectCircle = (id) => {
        if (selectedId !== id) {
            setSelectedId(id);
        }
    }

    const onTypeHandle = (e) => {
        updatePlatformHandle(platforms[selectedId], e.target.value);
    }

    const onSubmitForm = (e) => {
        // if all THREE payload attributes dont exist, return
        if (!payload.handles[platforms[0]] && !payload.handles[platforms[1]] && !payload.handles[platforms[2]]) {
            return;
        }

        goToCard(5);
    }

    const platforms = ["youtube", "tiktok", "instagram"];

    const placeholders = ["youtube.com/@", "tiktok.com/@", "instagram.com/"];

 
    return (
        <>
           
            <div class="creator-form" >
                <h1 id="form-h1">Establish Your Presence</h1>
                <p id="form-p">Enter your established public handle(s)<br/>
                and Gravidy will give you a head start!</p>
  
                <Circle selectedId={selectedId} selectCircle={selectCircle}></Circle>
                <input 
                    class="creator-input" 
                    type="text" 
                    name="handle" 
                    value={payload.handles[platforms[selectedId]] || ''}
                    onChange={onTypeHandle}
                    placeholder={placeholders[selectedId] || '@handle'} />
                <p id="form-p" style={{fontStyle: "italic"}}>By clicking continue, you agree to Gravidy setting up your account and transferring content. You may redeem or remove it at any time.</p>
                <div id="verify-div">
                    <LigherTextButton style={{opacity: !payload.handles[platforms[0]] && !payload.handles[platforms[1]] && !payload.handles[platforms[2]] ? 0.3 : 1}} text="Continue" onClick={onSubmitForm} />
                </div>
            </div>
        </>
    );
}

const Card5 = ({updatePayload, goToCard, payload, onSubmitForm, error}) => {
    const onSelectTransfer = (e) => {
        updatePayload("acceptedTransfer", e.target.checked);
    }

    const [loading, setLoading] = useState(false);

    const onSubmit = (e) => {
        onSubmitForm({setLoading});
    }

    return (
        <>
            <div className="creator-handle">
    
                <div>
                {/* <h1 id='password-h1'>Next Steps</h1> */}
                <h1 id='password-h1'>Temporary password:<br/> {payload.password}</h1>
                <h2 id="password-h2">(We will email this to you too)</h2>

                <p id='password-p'>
                When we launch, download Gravidy to redeem your profile! </p> 
                <p id='password-p'> You’ll be given <b>Creator Pilot</b> status, giving you <i>exclusive access</i> to <b>uploading content</b> and <b>inviting other creators</b>.</p>
                </div>
                {error ? <p style={{color: "#F00"}}>Request failed. Please retry.</p> : null}

                <div id="verify-div">
                <TextButton style={{opacity: loading ? 0.25 : 1}} text="Complete" onClick={onSubmit} width={135} textSize="1.15rem"/>
                </div>
            </div>

            
        </>
    );
}
