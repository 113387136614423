import { NavBar } from "../../Components/navbar/navbar"
import React, { useState, useEffect} from 'react'
import './explore.css'
import Graph1 from "../../assets/images/high quality/explore page graph1.webp";
import Graph2 from "../../assets/images/high quality/explore page graph2.webp";
import Rectangle from "../../assets/images/high quality/explore page rectangle.webp";
import Phone from "../../assets/images/high quality/PhoneLogo.webp"; //explore page phone
import Heart from "../../assets/images/high quality/explore page heart.webp";
import Sphere from "../../assets/images/high quality/explore page sphere.webp";
import Line1 from "../../assets/images/high quality/explore page line1.webp";
import Line2 from "../../assets/images/high quality/explore page line2.webp";
import Line3 from "../../assets/images/high quality/explore page line3.webp";
import User from "../../assets/images/high quality/HTSymbol.webp"; //explore page user
import { CanvasChild } from "../../Components/CanvasChild/CanvasChild";
import { Link, useParams } from "react-router-dom";
import { CREATOR_SIGNUP_ENDPOINT } from "../../App";


export const ExplorePage = () => {
    // if variant == "form" then it contains button to go back to creator sign up page
    // if variant == "thankyou" then it displays a registered message
    const { variant } = useParams();

    const [state, setState] = useState("Default")
    const [stateIndex, setStateIndex] = useState(0)
    const states = ["Default", "graph", "user", "phone"]
    

    const handleItemClick = (name) => {
        if (name === "graph1" || name === "graph2") {
            setState("graph");
            setStateIndex(1);
        } else if (name === "user") {
            setState("user");
            setStateIndex(2);
        } else if (name === "phone" || name === "heart") {
            setState("phone");
            setStateIndex(3);
        }
    };

    const handleNextClick = () => {
        let nextIndex = (stateIndex - 1 + 4) % 4;
        setStateIndex(nextIndex);
        setState(states[(nextIndex + 1) % 4]);
    }

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }
      
    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
      
        useEffect(() => {
          function handleResize() {
            setWindowDimensions(getWindowDimensions());
          }
      
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
        }, []);
      
        return windowDimensions;
    }

    const { width, height } = useWindowDimensions();

    const imagesDesktop = [
        { src: Line1, x: 0.444, y: 0.099, width: 0.033, height: 0.033, name: "line1"},
        { src: Line2, x: 0.514, y: 0.099, width: 0.152, height: 0.066, name: "line2"},
        { src: Line3, x: 0.268, y: 0.251, width: 0.168, height: 0.119, name: "line3"},
        { src: Rectangle, x: 0.664, y: 0.0198, width: 0.28, height: 0.390, name: "rectangle"},
        { src: Phone, x: -0.01, y: 0.0331, width: 0.397, height: 0.463, name: "phone"},
        { src: Heart, x: 0.0631, y: 0.192, width: 0.0493, height: 0.0328, glow: true, name: "heart"},
        //{ src: Sphere, x: 0.658, y: 0.0959, width: 0.0165, height: 0.0165, name: "sphere"},
        { src: Sphere, x: 0.256, y: 0.261, width: 0.0165, height: 0.0165, name: "sphere"},    
        { src: Graph2, x: 0.476, y: 0.0496, width: 0.129, height: 0.099, name: "graph2"},      
        { src: Graph1, x: 0.367, y: 0.132, width: 0.168, height: 0.132, name: "graph1"},
        { src: User, x: 0.324, y: 0.311, width: 0.11, height: 0.11, glow: true, name: "user", zIndex: 100},
        { src: null, x: 0.664, y: 0.0198, width: 0.28, height: 0.370, glow: true, name: "text"},
        { src: null, x: 0.922, y: 0.036, width: 0.1, height: 0.05, glow: true, name: "nextbutton", zIndex: 10000, onNextClick: handleNextClick}
    ]

    const imagesMobile = [
        { src: Line1, x: 0.644, y: 0.089, width: 0.063, height: 0.063, name: "line1"},
        { src: Line2, x: 0.654, y: 0.099, width: 0.35, height: 0.15, name: "line2"},
        { src: Line3, x: 0.358, y: 0.251, width: 0.30, height: 0.179, name: "line3"},
        { src: Rectangle, x: 0.081, y: 0.6198, width: 0.85, height: 1.20, name: "rectangle"},
        { src: Phone, x: 0.042, y: 0.0331, width: 0.486, height: 0.579, name: "phone"},
        { src: Heart, x: 0.131, y: 0.25, width: 0.0582, height: 0.0391, glow: true, name: "heart"},
        //{ src: Sphere, x: 0.7, y: 0.0859, width: 0.0215, height: 0.0215, name: "sphere"},
        { src: Sphere, x: 0.346, y: 0.2661, width: 0.0215, height: 0.0215, name: "sphere"},
        { src: Graph2, x: 0.706, y: 0.0496, width: 0.155, height: 0.1188, name: "graph2"},      
        { src: Graph1, x: 0.577, y: 0.152, width: 0.2017, height: 0.158, name: "graph1"},
        { src: User, x: 0.44, y: 0.341, width: 0.1754, height: 0.1754, glow: true, name: "user", zIndex: 100},
        { src: null, x: 0.081, y: 0.6198, width: 0.85, height: 1.20, glow: true, name: "text"},
        { src: null, x: 0.86, y: .68, width: 0.2, height: 0.05, glow: true, name: "nextbutton", zIndex: 10000, onNextClick: handleNextClick}
    ]


    let images;
    if (width > height) {
        images = imagesDesktop;
    } else {
        images = imagesMobile;
    }

    // useEffect(() => {
    //     console.log(state)
    //     console.log(window.innerWidth)
    // }, [state])
    

    return (
        <div className="canvas">
           <NavBar /> 
           {variant != undefined && variant == "form" ? <h1 id="goback"><Link to={CREATOR_SIGNUP_ENDPOINT}>← Return to Creator Pilot</Link></h1> : variant == "thankyou" ? <h2 id="welcome">Welcome.</h2> : <></>}
           <div data-aos="fade-down" className="canvas-container">
            {images.map((image, index) => (
                <CanvasChild state={state} windowHeight = {height} windowWidth={width} name={image.name} onNextClick = {handleNextClick} onClick={handleItemClick} key={index} y={image.y} x={image.x} width={image.width} height={image.height} src={image.src} glow={image.glow}/>
            ))}
           </div>
        </div>
    )
}