import React, { useReducer, useContext, useEffect, useState } from "react";
import siteReducer from "./siteReducer";
import { API_URL } from "./API_URL";
import axios from "axios";
import rg4js from 'raygun4js';
import { POST_CP_SIGN_UP_EMAIL_ENDPOINT, POST_REGISTER_EMAIL_ENDPOINT } from "./APPSTORE_URL";

const initialSiteState = {};

const SiteContext = React.createContext(initialSiteState);

const SiteProvider = ({ children }) => {
  const [state, dispatch] = useReducer(siteReducer, initialSiteState);

  const screenDimensions = useWindowDimensions();

  // During the register process, the user will be prompted to enter email for authentication
  const email2fa = async ({ email }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "auth/email2fa",
        data: { email },
      });

      return data;
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('email2fa failed. Error: ' + error)
      });
      return null;
    }
  };

  // After the user has entered the 2fa code, verify that email and code match
  const email2faCheck = async ({ email, emailCode }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "auth/email2fa/check",
        data: { email, emailCode },
      });

      return data.result;
    
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('email2faCheck failed. Error: ' + error)
      });
    }
  };

  const text2fa = async ({ phone }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "auth/text2fa",
        data: { phone_number: phone },
      });

      return data;
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('text2fa failed. Error: ' + error)
      });
    }
  };

  // After the user has entered the 2fa code, verify that number and code match
  const text2faCheck = async ({ phone, textCode }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "auth/text2fa/check",
        data: { phone, textCode },
      });

      return data.result;
    
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('text2faCheck failed. Error: ' + error)
      });
    }
  };

  // When the user presses register button, check if the username has been taken
  const usernameCheck = async ({ username }) => {
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "auth/username",
        data: { username },
      });
      return data.available;
    
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('usernameCheck failed. Error: ' + error)
      });
    }
  };

  // // When the user high tier invite is input, automatically high tier follow the creator
  // const highTierFollow = async ({ beingFollowedUserID }) => {
  //   const isHighTier = true;
  //   try {
  //     const { data } = await axios({
  //       method: "post",
  //       url: API_URL + "follows/follow",
  //       data: { beingFollowedUserID, isHighTier },
  //     });

  //     return data.result;
    
  //   } catch (error) {
  //     console.log(error);
  //     rg4js('send', {
  //       error: new Error('highTierFollow failed. Error: ' + error)
  //     });
  //   }
  // };

  const uploadCreatorForm = async (payload) => { 
    let result = false;
    try {
      console.log(payload)
      let { data } = await axios({
        method: "post",
        url: API_URL + "c_river/creatorq",
        data: payload,
      });

      result = data.result;
    
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('uploadCreatorForm failed. Error: ' + error)
      });
      return null;
    }
    try {
      // TRIGGER EMAIL SEND HERE!
      const resp = await axios({
        method: "post",
        url: API_URL + POST_CP_SIGN_UP_EMAIL_ENDPOINT,
        data: payload,
      });
    } catch (error) {
      console.log(error)
    }

    return result;
  }


  // Add functionality for registering the user,
  // with optional creator token and high tier creator specified
  const register = async ({ 
    username, 
    password, 
    birthday, 
    phone, 
    textCode,
    email, 
    emailCode,
    highTierUID 
  }) => {
    let user = { username, password, birthday, pronouns: "none" };
    
    if (phone != undefined) {
      user = { ...user, phone, textCode };
    } 
    if (email != undefined && emailCode != undefined) {
      user = { ...user, email, emailCode };
    }

    let payload = { user };
    if (highTierUID != undefined) {
      payload = { ...payload, highTierUID };
    }
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "auth/register",
        data: payload,
      });
      
    } catch (error) {
      console.log(error);
      rg4js('send', {
        error: new Error('register failed. Error: ' + error)
      });
      return null;
    }
    try {
    // TRIGGER EMAIL SEND HERE!
    const resp = await axios({
      method: "post",
      url: API_URL + POST_REGISTER_EMAIL_ENDPOINT,
      data: payload,
    });
  } catch (error) {
    console.log(error)
  }
    
    return true;
  };

  /**
   * 
   * @param {*} userID 
   * @returns {name, username}
   */
  const getUsername = async ({userID}) => {
    try {
      console.log(userID)
      const { data } = await axios({
        method: "post",
        url: API_URL + "users/names_u",
        data: { userID }
      });

      return data.item;

    } catch (error) {
      console.log('here')
      rg4js('send', {
        error: new Error('Get Username failed: ' + error)
      });
      return {};
    }
  }

  /**
   * 
   * @param {String} username 
   * @returns userID or null if not found
   */
  const getUserIDFromUsername = async ({username}) => {
    try {
      const { data } = await axios({
        method: "post",
        url: API_URL + "users/get_user_id",
        data: { username }
      });

      return data.userID;

    } catch (error) {
      console.log('here')
      rg4js('send', {
        error: new Error('Get UserID failed: ' + error)
      });
      return null;
    }
  }

  return (
    <SiteContext.Provider value={{ ...state, email2fa, email2faCheck, text2fa, text2faCheck, usernameCheck, register, uploadCreatorForm, getUsername, getUserIDFromUsername, screenDimensions}}>
      {children}
    </SiteContext.Provider>
  );
};

const useSiteContext = () => {
  return useContext(SiteContext);
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export { SiteProvider, useSiteContext };
